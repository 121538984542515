import React, { memo, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";
import "../styles/App.scss";
import vwLogo from "../assets/images/vw-logo.png";
import { Modal, Steps } from "antd";
import CreatePassword from "./completeDetails/CreatePassword";
import TeamSize from "./completeDetails/TeamSize";
import Details from "./completeDetails/Details";
import MobileSignUp from "./mobileVerify/MobileSignUp";
import EmailSignUp from "./emailVerfy/EmailSignup";
import {
  email,
  endPoint,
  firstName,
  lastName,
  leadId,
  mobileMandatory,
  orgName,
  subdomainCode,
  tldId,
  vwpOrgId,
} from "../features/userData/userDataSlice";
import { useDispatch } from "react-redux";
import { getDetailsOfNonaccountSignUpService } from "../services/getDetailsOfNonaccountSignUpService";
import { useLocation } from "react-router-dom";
import { getEndPointByTldIdService } from "../services/getEndPointByTldIdService";
import { fetchImageUrlService } from "../services/fetchImageUrlService";
import { Skeleton } from "antd";

const NonAccountUserTemplate = ({ stepNumber, imageUrl }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  console.log("stepNumber in no account", stepNumber);
  const [inviteUserDetailsStatus, setInviteUserDetailsStatus] = useState(false);
  const [tldStatus, setTldstatus] = useState(true);
  const [isSignUpComplete, setIsSignUpComplete] = useState();

  ///start of image url

  //end of image url

  //getting details of invite user details
  const getDetails = async () => {
    setInviteUserDetailsStatus(true);
    const data = {
      platformUserId: location?.search?.split("&")[1].split("=")[1],
    };

    console.log("platformuserId", data);
    try {
      const response = await getDetailsOfNonaccountSignUpService(data);
      setInviteUserDetailsStatus(false);
      if (response.status === "success") {
        console.log("redux details", response);
        dispatch(leadId(response?.data?.dbResponse?.vlsUserId));
        dispatch(firstName(response?.data?.dbResponse?.firstname));
        dispatch(lastName(response?.data.dbResponse?.lastname));
        dispatch(email(response?.data?.dbResponse?.email));
        dispatch(orgName(response?.data?.dbResponse?.orgName));
        dispatch(subdomainCode(response?.data?.dbResponse?.subdomainCode));
        dispatch(vwpOrgId(response?.data?.dbResponse?.vwpOrgId));
        dispatch(mobileMandatory(response?.data.dbResponse.mobileMandatory));
        setIsSignUpComplete(response?.data.dbResponse.isSignUpComplete);
      } else {
        console.log("not got the details");
      }
    } catch (err) {
      console.log("getTldIdErr", err);
      setInviteUserDetailsStatus(false);
    }
  };
  useEffect(() => {
    getDetails();
    return () => {};
  }, []);

  // const getTldId = async () => {
  //   const data = {
  //     tldId: location?.search?.split("&")[1].split("=")[1],
  //   };
  //   try {
  //     const getTldIdResponse = await getEndPointByTldIdService(data);

  //     if (getTldIdResponse.data.isTldIdExist) {
  //       dispatch(endPoint(getTldIdResponse.data.dbResponse.vwEndpoint));
  //       setTldstatus(true);
  //       console.log(
  //         getTldIdResponse.data.dbResponse.vwEndpoint,
  //         "endpointbasedontldId"
  //       );
  //     } else {
  //       setTldstatus("TldId is not valid");
  //     }
  //   } catch (err) {
  //     console.log("getTldIdErr", err);
  //     setTldstatus("TldId is not valid");
  //   }
  // };

  // const getTldId = async () => {
  //   const data = {
  //     endPoint: window.location.host.split("onboarding.")[1],
  //     // endPoint: "voltuswave.com",
  //   };
  //   try {
  //     console.log(
  //       "data in non ",
  //       data,
  //       window.location.host.split("onboarding.")
  //     );
  //     const getTldIdResponse = await getEndPointByTldIdService(data);
  //     console.log("getTldIdResponse", getTldIdResponse.data);
  //     if (getTldIdResponse.data.isTldIdExist) {
  //       dispatch(endPoint(getTldIdResponse.data.dbResponse.vwEndpoint));
  //       dispatch(tldId(getTldIdResponse.data.dbResponse.tldId));
  //       setTldstatus(true);
  //       console.log(
  //         getTldIdResponse.data.dbResponse.vwEndpoint,
  //         "endpointbasedontldId"
  //       );
  //     } else {
  //       setTldstatus("TldId is not valid");
  //     }
  //   } catch (err) {
  //     console.log("getTldIdErr", err);
  //     setTldstatus("TldId is not valid");
  //   }
  // };
  // useEffect(() => {
  //   getTldId();
  //   return () => {};
  // }, []);

  return (
    <>
      {/* <ToastContainer position="top-center" autoClose={2000} /> */}
      {/* <Modal
        open={true}
        closable={false}
        footer={null}
        width="730px"
        className="modal"
      > */}
      <div className="modal">
        <div className="vwContainer">
          <div className="vwHeader">
            <div className="vwLogo">
              {imageUrl && (
                <img
                  src={imageUrl}
                  width="200px"
                  alt="compaign logo"
                  srcSet=""
                />
              )}
              {!imageUrl && (
                <div style={{ width: "200px", height: "38px" }}>
                  <Skeleton.Input active={true} size="200px" block={true} />
                </div>
              )}
            </div>
          </div>
          <Steps
            className="signup-steps"
            size="small"
            current={stepNumber}
            responsive={false}
            items={[{}, {}, {}]}
          />
          {stepNumber == 0 && (
            <MobileSignUp
              inviteUserDetailsStatus={inviteUserDetailsStatus}
              tldStatus={tldStatus}
              isSignUpComplete={isSignUpComplete}
              nextStep={1}
            />
          )}
          {stepNumber == 1 && <Details nextStep={2}/>}
          {stepNumber == 2 && <CreatePassword />}
        </div>
        {/* </Modal> */}
      </div>
    </>
  );
};

export default memo(NonAccountUserTemplate);
