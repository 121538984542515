import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import { Provider } from "react-redux";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
  HttpLink,
  ApolloLink,
  useLazyQuery,
} from "@apollo/client";
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { getGqlAccessDataHandler } from "./services/signupApiService";
import { HelmetProvider } from "react-helmet-async";

const GraphqlAccessHandler = () => {
  const [isData, setIsData] = useState(false);
  const [gqlAceesData, setGqlAceesData] = useState({});

  const url = gqlAceesData?.awsappsyncgraphqlEndpoint;

  const region = gqlAceesData.awsappsyncregion;

  const auth = {
    type: gqlAceesData.awsappsyncauthenticationType,
    apiKey: gqlAceesData.awsappsyncapiKey,
    // jwtToken: async () => token, // Required when you use Cognito UserPools OR OpenID Connect. token object is obtained previously
    // credentials: async () => credentials, // Required when you use IAM-based auth.
  };

  const getGQLAccessData = async () => {
    try {
      const response = await getGqlAccessDataHandler({ key: "app sync" });
      console.log("get gql access  response:", response);

      if (response.status === "success" && response.data.status === 200) {
        setGqlAceesData(response.data.dbResponse);
        setIsData(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log({ isData });

  React.useEffect(() => {
    getGQLAccessData();
  }, []);

  const httpLink = new HttpLink({ uri: url });

  const link = ApolloLink.from([
    createAuthLink({ url, region, auth }),
    createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
  ]);

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
  });

  return isData ? (
    <ApolloProvider client={client}>
      <HelmetProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </HelmetProvider>
    </ApolloProvider>
  ) : (
    <div />
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<GraphqlAccessHandler />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
