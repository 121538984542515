import axios from "axios";
import React, { useState, useEffect, useDebugValue } from "react";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
// import { json, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  mobile,
  stepNavigate,
  isMobileNumberVerified,
  firstName,
  endPoint,
  leadId,
  lastName,
} from "../../features/userData/userDataSlice";
import { MobileSignupService } from "../../services/signupApiService";
import {
  LottieLoaders,
  getGeoApiLocation,
  loader,
  onkeyPressHandler,
} from "../../utilies/Utilies";
import MobileOtpVerify from "./MobileOtpVerify";
import { getCountryCallingCode } from "react-phone-number-input";
import { mobileOtpVerifyService } from "../../services/otpVerifyApiServices";
import {
  existMobileNumberCheckingService,
  existMobileNumberCheckingServiceInVwp,
} from "../../services/existEmailAndMobileService";
import { Button, Spin } from "antd";
import { useLocation } from "react-router-dom";
import { getEndPointByTldIdService } from "../../services/getEndPointByTldIdService";
import { LoadingOutlined } from "@ant-design/icons";
import Details from "../completeDetails/Details";
import { PoweroffOutlined } from '@ant-design/icons';
import { lottieConstants } from "../../utilies/Utilies";

const SignUp = (props) => {
  const {
    inviteUserDetailsStatus,
    tldStatus,
    isEmailOptional = true,
    nextStep = "",
    emailRequired=true,
    isOptional="",
    isSignUpComplete
  } = props;
  // const navigate = useNavigate();
  const [type, setType] = useState("mobile");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [countryCode, setCountryCode] = useState();
  const [errorMsg, setErrorMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeId, setActiveId] = useState("submitBtn");
  const [prefixNumber, setPrefixNumber] = useState();
  const [isMobileNumberExist, setIsMobileNumberExist] = useState("");
  const [isTldIdExist, setisTldIdExist] = useState(false);
  const [mobileMandatory, setMobileMandatory] = useState(true);
  const [isMobileNumberValidation, setIsMobileNumberValidation] =
    useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);

  console.log("state:in mobile", userData);
  const location = useLocation();
  console.log("mobileMandatory:", mobileMandatory);

  const signupType =
    location.search?.split("&")[0]?.split("=")[1] === "account"
      ? "account"
      : "invite";

  console.log("dispatch:", signupType);
  console.log(prefixNumber, "dfsdfd");
  const getDetails = () => {
    try {
      if (userData) {
        setUserFirstName(userData?.firstName);
        setUserLastName(userData?.lastName);
        setUserEmail(userData?.email);
        setUserMobile(userData?.mobile);
        setCountryCode(userData?.code);
        setMobileMandatory(userData?.isMobileMandatory);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandler = async () => {
    try {
      setLoading(true);

      let data = {
        leadId: userData?.leadId,
        leadMobile: userMobile,
        countryCode: countryCode,
        mobileCode: prefixNumber ?? `+${getCountryCallingCode(countryCode)}`,
        journeyIndex: 3,
        firstName:userFirstName,
        lastName:userLastName,
        email:userEmail,
      };
      const response = await MobileSignupService(data);
      // console.log("mobile signup responce", response);
      if (
        response?.status === "success" &&
        response?.data?.mobileOtpGenerated &&
        response?.data?.dbResponse?.isMobileOtpSend
      ) {
        setLoading(false);
        dispatch(mobile(response?.data?.dbResponse?.mobile));
        dispatch(isMobileNumberVerified(true));
        dispatch(firstName(response?.data?.dbResponse?.firstname))
        dispatch(lastName(response?.data?.dbResponse?.lastname))
        response?.data?.dbResponse?.leadId&& dispatch(leadId(response?.data?.dbResponse?.leadId))
        // dispatch(stepNavigate(nextStep));
        // navigate("/mobileOtpVerify");
      } else {
        setLoading(false);
        toast.error("something went wrong");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("something went wrong");
    }
  };

  // const isMobileNumberExistHandler = async () => {
  //   if (formvalidations()) {
  //     try {
  //       console.log("hi11");
  //       setLoading(true);
  //       setIsMobileNumberExist(false);
  //       let data = {
  //         mobileNumber: userMobile,
  //         endPoint: userData.endPoint,
  //       };
  //       const vwpResponse = await existMobileNumberCheckingServiceInVwp(data);
  //       console.log("vwpemailexist", vwpResponse);
  //       if (vwpResponse.data.available === true) {
  //         const response = await existMobileNumberCheckingService(data);
  //         if (response?.status === "success") {
  //           submitHandler();
  //         } else {
  //           setIsMobileNumberExist(true);
  //           setIsMobileNumberExist("mobile number exist already");
  //           setLoading(false);
  //         }
  //       } else {
  //         setIsMobileNumberExist(true);
  //         setIsMobileNumberExist("mobile number exist already");
  //         setLoading(false);
  //       }
  //       // console.log("mobile signup responce", response);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };

  const isMobileNumberExistHandler = async () => {
    if (formvalidations()) {
      try {
        setLoading(true);
        setIsMobileNumberExist(false);
        let data = {
          mobileNumber: userMobile,
        };
        // const response = await existMobileNumberCheckingService(data);
        // console.log("mobile signup responce", response);
        // if (response?.status === "success") {
        if (true) {
          submitHandler();
        } else {
          setIsMobileNumberExist(true);
          setIsMobileNumberExist("mobile number exist already");
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const previousBtnHandler = () => {
    dispatch(stepNavigate(0));
  };

  console.log("country code:", countryCode);

  useEffect(() => {
    getDetails();
    getGeoApiLocation(setCountryCode, type,userData.ipnfoToken);
  }, [userData]);

  const skipMobileNo = () => {
   
      dispatch(stepNavigate(nextStep));
    
  };

  let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const formvalidations = () => {
    let isValid = true;

    setIsMobileNumberValidation(false);

    if (!userFirstName) {
      isValid = false;
      setErrorMsg(true);
    }
    // else if (!userEmail.match(regex)) {
    //   isValid = false;
    //   setErrorMsg(true);
    // }
    else if (!userMobile) {
      isValid = false;
      setErrorMsg(true);
    }
    // else if (userMobile.length < 10) {
    //   isValid = false;
    //   setIsMobileNumberValidation(true);
    // } else if (
    //   userMobile[0] === "0" ||
    //   userMobile[0] === "1" ||
    //   userMobile[0] === "2" ||
    //   userMobile[0] === "3" ||
    //   userMobile[0] === "4" ||
    //   userMobile[0] === "5"
    // ) {
    //   isValid = false;
    //   setIsMobileNumberValidation(true);
    // }
    return isValid;
  };
  // console.log();

  const spinStatus = () => {
    if (signupType === "account") {
      return false;
    } else if (isSignUpComplete !== "false") {
      return true;
    } else {
      return inviteUserDetailsStatus;
    }
  };

  console.log(countryCode, "countrycodekkkk");
  const handleChange = (e) => {
    setIsMobileNumberValidation(false);
    setUserMobile(e.target.value);
  };

  console.log({ isSignUpComplete }, typeof isSignUpComplete);

  const indicatroStatus = () => {
    console.log("indicatroStatus", spinStatus());
    if (inviteUserDetailsStatus) {
      return <LottieLoaders lottieConstant={lottieConstants.loaderBalls} />;
    } else if (isSignUpComplete !== "false") {
      return (
        <div>
          {/* <LottieLoaders lottieConstant={lottieConstants.invalidPage} /> */}
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              marginLeft: "-80px",
              color: "black",
              fontWeight: "bold",
            }}
          >
            <p>Web &nbsp;</p>&nbsp;<p>URL </p>&nbsp;
            <p> is </p>&nbsp;
            <p>Expired</p>
          </div>
        </div>
      );
    }
  };
  return (
    <div>
      <Spin
        spinning={isEmailOptional ? !isEmailOptional : spinStatus()}
        indicator={<LottieLoaders />}
      >
        <div className="vwContainer mobileSignup">
          {/* <div className="vwHeader">
          <div className="vwLogo">
            <img src={vwLogo} width="200px" alt="voltuswave logo" srcSet="" />
          </div>
        </div> */}
          {!userData.isMobileNumberVerified && (
            <div className="vwContent">
              <div className="vwSignUpHeadText">
                <strong>Sign Up</strong>
                <br />
                <small className="text">
                  Get started today by entering a few details
                </small>
              </div>

              <div className="row inputField">
                <div className="col">
                  <label>First Name * </label>

                  <div>
                    <input
                      type="text"
                      className="form-control"
                      value={userFirstName}
                      onChange={(e) => {
                        setUserFirstName(e.target.value);
                      }}
                      name="firstName"
                      disabled={!isEmailOptional}
                    />
                  </div>
                  <div>
                    {errorMsg && !userFirstName && (
                      <span className="errorMsg">First name is required</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row inputField">
                <div className="col">
                  <label>Last Name</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      value={userLastName}
                      onChange={(e) => {
                        setUserLastName(e.target.value);
                      }}
                      name="lastName"
                      disabled={!isEmailOptional}
                    />
                  </div>
                  {/* <div>
                                {errorMsg && !userDetails.lastName && <span className='errorMsg'>last name is required</span>}
                            </div> */}
                </div>
              </div>
            {emailRequired&&  <div className="row inputField">
                <div className="col">
                  <label>Email </label>
                  <div>
                    <input
                      type="email"
                      className="form-control"
                      value={userEmail}
                      onChange={(e) => {
                        setUserEmail(e.target.value);
                      }}
                      name="email"
                      disabled
                    />
                    {/* <div>
                      {(errorMsg && !userEmail && (
                        <span className="errorMsg">Email is required</span>
                      )) ||
                        (errorMsg && !userEmail.match(regex) && (
                          <span className="errorMsg">
                            Please enter valid email
                          </span>
                        ))}
                    </div> */}
                  </div>
                </div>
              </div>}

              <div className="row mobileContainer">
                <div className="col">
                  <label>{`Mobile Number ${
                    mobileMandatory === "true" ? "*" : ""
                  }`}</label>
                  <div className="mobileField">
                    <PhoneInput
                      international
                      defaultCountry={countryCode}
                      onCountryChange={setCountryCode}
                      value={prefixNumber}
                      onChange={(country) => {
                        console.log("country ", country);
                        setPrefixNumber(country);
                      }}
                      // disabled
                    />
                    <input
                      type="number"
                      // max={"10"}
                      className="mobileNumber"
                      value={userMobile}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-", "."].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onKeyPress={(event) => {
                        onkeyPressHandler(event, activeId);
                      }}
                    />
                  </div>
                  {isMobileNumberExist && (
                    <div className="errorMsg">{isMobileNumberExist}</div>
                  )}
                  <div>
                    {errorMsg && !userMobile && (
                      <span className="errorMsg">
                        Mobile number is required
                      </span>
                    )}
                    {isMobileNumberValidation && (
                      <span className="errorMsg">Invalid Mobile Number</span>
                    )}
                  </div>
                </div>
              </div>
              <div>
               
              </div>

              <div className="buttonContainer">
                {/* <button className="previousBtn" onClick={previousBtnHandler}>
                Previous
              </button> */}
               {isOptional&& (
                   <button
                   id="previousBtn"
                   className="previousBtn"
                   onKeyUp={skipMobileNo}
                   onClick={skipMobileNo}
                 >
                   Skip
                 </button>
                )}
                <button
                  id="submitBtn"
                  className="submitBtn"
                  style={{
                    backgroundColor:
                      userData.endPoint.length === 0 ? "#7bb0fa" : "  ",
                    cursor:
                      userData.endPoint.length === 0
                        ? "not-allowed"
                        : "pointer",
                  }}
                  onKeyUp={isMobileNumberExistHandler}
                  onClick={isMobileNumberExistHandler}
                  disabled={userData.endPoint.length === 0}
                >
                  {loading ? loader(loading) : "Next"}
                </button>
                <div style={{ marginTop: "10px" }}>
                  {tldStatus && <span className="errorMsg">{tldStatus}</span>}
                </div>
              </div>
            </div>
          )}
          {userData.isMobileNumberVerified && (
            <MobileOtpVerify nextStep={nextStep} />
          )}
        </div>
      </Spin>
    </div>
  );
};

export default SignUp;
