import instanceAll from "./commonAxios";

export const emailOtpVerifyService = async (data) => {
	try {
		const response = await instanceAll.post("/verifyEmailOtp", data);
		console.log("response:", response);
		if(response?.status === 200 ){
			return {
			 status :"success",
			 data:response?.data
			}
		 }
	} catch (error) {
		console.log(error);
		return {
			status :400,
			error : error.response.data
		}

	}
};

export const mobileOtpVerifyService = async (data) => {
	try {
		const response = await instanceAll.post("/verifyMobileOtp", data);
		// console.log("response:", response);
		if(response?.status === 200){
			return {
			 status :"success",
			 data:response?.data
			}
		 }
	} catch (error) {
		console.log(error);
		return {
			status :400,
			error : error.response.data
		}
	}
};
