import React, { useEffect, useState } from "react";
import AccountUserTemplate from "./AccountUserTemplate";
import NonAccountUserTemplate from "./NonAccountUserTemplate";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ipnfoToken, isAccount } from "../features/userData/userDataSlice";
// import vwLogo from "../assets/images/vw-logo.png";
import { Helmet } from "react-helmet-async";
import { Skeleton } from "antd";
import { tldUrl, endPoint, tldId } from "../features/userData/userDataSlice";
import { getEndPointByTldIdService } from "../services/getEndPointByTldIdService";
import { signupTypeInUrl } from "../features/userData/userDataSlice";
import NewOrgCreationBySchemaId from "./userCreationBySchemaId/NewOrgCreationBySchemaId";
import OrgJoinBySchemaId from "./userCreationBySchemaId/OrgJoinBySchemaId";
import queryString from "query-string";

function ModalComponent(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { stepNumber } = props;

  let schemaId;
  let UserTemplate;

  const isAccountUser =
    location.search?.split("&")[0]?.split("=")[1] === "account";
  // dispatch(isAccount(isAccountUser));
  const [imageUrl, setImageUrl] = useState("");
  const [ImageData, setImageData] = useState("");
  // const [campaignId, setCampaignId] = useState("");
  let signupType = location.search?.split("&")[0]?.split("=")[1];
  const isAccount = signupType === "account";

  // const campaignId =
  //   location.search?.split("&")[2]?.split("=")[1] === undefined
  //     ? location.search?.split("&")[1]?.split("=")[1]
  //     : location.search?.split("&")[2]?.split("=")[1];

  // console.log("location+++", campaignId);

  const queryObject = queryString.parse(location.search);
  const keysOfQueryParams = Object.keys(queryObject);
  const signupSchemeID = "signupSchemeID";
  const orgJoinSchemeID = "orgJoinSchemeID";
  console.log(
    "keysOfQueryParams.includes(kkk)",
    keysOfQueryParams.includes(orgJoinSchemeID)
  );
  console.log("query params", queryObject, queryObject.signupSchemeID);

  if (keysOfQueryParams.includes(signupSchemeID)) {
    signupType = "signupSchemeID";
    schemaId = queryObject[signupType];
    console.log("signupSchemeID", schemaId);
    UserTemplate = NewOrgCreationBySchemaId;
  } else if (keysOfQueryParams.includes(orgJoinSchemeID)) {
    signupType = "orgJoinSchemeID";
    schemaId = queryObject[signupType];
    console.log("orgJoinSchemeID", schemaId);
    UserTemplate = OrgJoinBySchemaId;
  } else {
    UserTemplate = isAccountUser ? AccountUserTemplate : NonAccountUserTemplate;
  }
  dispatch(signupTypeInUrl(signupType));

  const voltuswave = "voltuswave.com";
  const voltussprint = "voltussprint.com";

  const fetchLogoUrl = async () => {
    const body = {
      endPoint: window.location.host.split("onboarding.")[1] || voltuswave,
    }; // add domain name in prod

    try {
      const platformDataRes = await getEndPointByTldIdService(body);
      console.log("platform data by domain:", platformDataRes);
      if (platformDataRes.status === "success") {
        setImageUrl(platformDataRes.data.dbResponse.platformLogo);
        setImageData(platformDataRes.data.dbResponse);
        // dispatch(tldUrl(platformDataRes.data.dbResponse.platformLogo));
        dispatch(endPoint(platformDataRes.data.dbResponse.vwEndpoint));
        dispatch(tldId(platformDataRes.data.dbResponse.tldId));
        dispatch(ipnfoToken(platformDataRes.data.dbResponse?.ipinfoTokenId));
      } else {
        // setImageUrl(vwLogo);
        // setImageData({
        //   ...ImageData,
        //   backgroundImage:
        //     "https://vls-app-screenshots.s3.amazonaws.com/compaignImages/voltuswavebackgroundImage.png",
        // });
      }
    } catch (err) {
      // setImageUrl(vwLogo);
      // setImageData({
      //   ...ImageData,
      //   backgroundImage:
      //     "https://vls-app-screenshots.s3.amazonaws.com/compaignImages/voltuswavebackgroundImage.png",
      // });
      console.log("err in fetching image", err);
    }
  };
  useEffect(() => {
    fetchLogoUrl();
  }, []);

  return (
    <div className="main-page-container">
      {ImageData ? (
        <div
          style={{ background: `url(${ImageData.backgroundImage}) no-repeat` }}
          className="bgImage"
        ></div>
      ) : (
        <div className="skeletonContainer">
          <Skeleton.Image active={true} size="large" />
        </div>
      )}
      <Helmet>
        <link rel="icon" href={ImageData.favIcon} type="image/x-icon" />
        <title>{ImageData.tldName}</title>
      </Helmet>
      <UserTemplate
        stepNumber={stepNumber}
        imageUrl={imageUrl}
        schemaId={schemaId}
        signupType={signupType}
      />
    </div>
  );
}

export default ModalComponent;

// import React, { memo } from "react";
// import { ToastContainer } from "react-toastify";
// import "react-phone-number-input/style.css";
// import "react-toastify/dist/ReactToastify.css";
// import "../styles/App.scss";
// import vwLogo from "../assets/images/vw-logo.png";
// import { Modal, Steps } from "antd";
// import CreatePassword from "./completeDetails/CreatePassword";
// import TeamSize from "./completeDetails/TeamSize";
// import Details from "./completeDetails/Details";
// import MobileSignUp from "./mobileVerify/MobileSignUp";
// import EmailSignUp from "./emailVerfy/EmailSignup";

// const ModalComponent = ({ stepNumber }) => {
//   return (
//     <div>
//       <ToastContainer position="top-center" autoClose={2000} />
//       <Modal
//         open={true}
//         closable={false}
//         footer={null}
//         width="730px"
//         className="modal"
//       >
//         <div className="vwContainer">
//           <div className="vwHeader">
//             <div className="vwLogo">
//               <img src={vwLogo} width="200px" alt="voltuswave logo" srcSet="" />
//             </div>
//           </div>
//           <Steps
//             className="signup-steps"
//             size="small"
//             current={stepNumber}
//             responsive={false}
//             items={[{}, {}, {}, {}, {}]}
//           />
//           {stepNumber == 0 && <EmailSignUp />}
//           {stepNumber == 1 && <MobileSignUp />}
//           {stepNumber == 2 && <Details />}
//           {stepNumber == 3 && <TeamSize />}
//           {stepNumber == 4 && <CreatePassword />}
//         </div>
//       </Modal>
//     </div>
//   );
// };

// export default memo(ModalComponent);
