import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  firstName: "",
  lastName: "",
  mobile: "",
  code: "",
  email: "",
  dateofBirth: "",
  gender: "",
  teamSize: "",
  orgName: "",
  subdomainCode: "",
  vwpOrgId: "",
  leadId: null,
  stepNumber: 0,
  isSocialLogin: false,
  isEmailVerified: false,
  isMobileNumberVerified: false,
  endPoint: "",
  tldId: "",
  isAccount: "",
  isMobileMandatory: null,
  tldUrl: "",
  signupTypeInUrl: "",
  schemaDetails: [],
  orgJoinOrgDetails:{},
  ipnfoToken:"83c4ac22d08c41"
};

export const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    leadId: (state, action) => {
      state.leadId = action.payload;
    },
    firstName: (state, action) => {
      state.firstName = action.payload;
    },
    lastName: (state, action) => {
      state.lastName = action.payload;
    },
    mobile: (state, action) => {
      state.mobile = action.payload;
    },
    code: (state, action) => {
      state.code = action.payload;
    },
    email: (state, action) => {
      state.email = action.payload;
    },

    dateofBirth: (state, action) => {
      state.dateofBirth = action.payload;
    },
    gender: (state, action) => {
      state.gender = action.payload;
    },
    teamSize: (state, action) => {
      state.teamSize = action.payload;
    },
    orgName: (state, action) => {
      state.orgName = action.payload;
    },
    subdomainCode: (state, action) => {
      state.subdomainCode = action.payload;
    },
    vwpOrgId: (state, action) => {
      state.vwpOrgId = action.payload;
    },

    stepNavigate: (state, action) => {
      state.stepNumber = action.payload;
    },
    isSocialLogin: (state, action) => {
      state.isSocialLogin = action.payload;
    },
    isEmailVerified: (state, action) => {
      state.isEmailVerified = action.payload;
    },
    isMobileNumberVerified: (state, action) => {
      state.isMobileNumberVerified = action.payload;
    },
    endPoint: (state, action) => {
      state.endPoint = action.payload;
    },
    tldId: (state, action) => {
      state.tldId = action.payload;
    },
    isAccount: (state, action) => {
      state.isAccount = action.payload;
    },
    mobileMandatory: (state, action) => {
      state.isMobileMandatory = action.payload;
    },
    tldUrl: (state, action) => {
      state.tldUrl = action.payload;
    },
    signupTypeInUrl: (state, action) => {
      state.signupTypeInUrl = action.payload;
    },
    schemaDetails: (state, action) => {
      state.schemaDetails = action.payload;
    },
    orgJoinOrgDetails:(state,action)=>{
      state.orgJoinOrgDetails = action.payload;
    },
    ipnfoToken:(state,action)=>{
      state.ipnfoToken = action.payload;
    }
  },
});

export const {
  firstName,
  lastName,
  mobile,
  code,
  email,
  dateofBirth,
  gender,
  teamSize,
  leadId,
  orgName,
  subdomainCode,
  vwpOrgId,
  stepNavigate,
  isSocialLogin,
  isEmailVerified,
  isMobileNumberVerified,
  endPoint,
  tldId,
  isAccount,
  mobileMandatory,
  tldUrl,
  signupTypeInUrl,
  schemaDetails,
  orgJoinOrgDetails,
  ipnfoToken
} = userDataSlice.actions;
export default userDataSlice.reducer;
