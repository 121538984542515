import instanceAll from "./commonAxios";
import axios from "axios";

export const emailCheckInOrgService = async (data) => {
  try {
    const response = await instanceAll.post("/emailCheckInOrg", data);
    console.log("response existEmailInOrg:", response);
    if (response?.status === 200) {
      return {
        status: "success",
        data: response?.data,
      };
    } else {
      return {
        status: "failed",
        data: response?.data,
      };
    }
  } catch (error) {
    console.log(error);
  }
};
