import { DatePicker, Radio } from "antd";
import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import vwLogo from "../../assets/images/vw-logo.png";
import { useDispatch, useSelector } from "react-redux";
import { identityDetailsService } from "../../services/detailsApiServices";
import {
  dateofBirth,
  gender,
  isMobileNumberVerified,
  stepNavigate,
} from "../../features/userData/userDataSlice";
import { toast } from "react-toastify";
import moment from "moment";
import { loader, onkeyPressHandler } from "../../utilies/Utilies";
import { Select, Space } from "antd";
import { useLocation } from "react-router-dom";
import { current } from "@reduxjs/toolkit";

const Details = ({nextStep=""}) => {
  const monthsData = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [months, setMonths] = useState(monthsData);

  let years = new Date().getFullYear();
  // new Date().getMonth() + 1;
  // console.log(monthss, "month");
  // const navigate = useNavigate();
  const [dob, setDob] = useState(null);
  const [userGender, setUserGender] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);
  const [day, setDay] = useState();
  const [month, setMonth] = useState("March");
  const [year, setYear] = useState(new Date().getFullYear());
  const [currentMonth, setcurrentMonth] = useState(new Date().getMonth() + 1);
  const [loading, setLoading] = useState(false);
  const [activeId, setActiveId] = useState("submitBtn");
  const [days, setDays] = useState(new Date(year, currentMonth, 0).getDate());
  const location = useLocation();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);

  const signupType = location.search?.split("&")[0]?.split("=")[1];
  const isAccount = signupType === "account";

  const genderHandler = (e) => {
    setUserGender(e.target.value);
  };
  const monthsNumbering = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };
  const convertMonthToNumber = () => {
    // console.log(currentMonth, 1, month);
    setcurrentMonth(monthsNumbering[month]);
    // console.log(currentMonth, 2, month);
    // console.log("render");
  };

  const submitHandler = async () => {
    try {
      if (formValidation()) {
        setLoading(true);
        let data = {
          leadId: userData?.leadId,
          leadDob: `${year}-${month}-${day}`,
          leadGender: userGender,
          journeyIndex: 5,
        };
        const response = await identityDetailsService(data);
        // console.log("identity Details Response:", response);
        if (
          response?.status === "success" &&
          response?.data?.addedIdentityDetails
        ) {
          setLoading(false);
          dispatch(dateofBirth(response?.data?.dbResponse?.dob));
          dispatch(gender(response?.data?.dbResponse?.gender));

          if (location.search?.split("&")[0]?.split("=")[1] === "account") {
            dispatch(stepNavigate(nextStep));
          } else {
            dispatch(stepNavigate(nextStep));
          }
          // navigate("/teamStrength");
        } else {
          setLoading(false);
          toast.error("something went wrong");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const previousBtnHandler = () => {
    if (isAccount) {
      dispatch(stepNavigate(1));
    } else {
      dispatch(isMobileNumberVerified(false));
      dispatch(stepNavigate(0));
    }
  };

  const getUserDetails = () => {
    setDob(userData?.dateofBirth);
    let userDateOfBirth = userData?.dateofBirth;
    setDay(
      userDateOfBirth
        ? moment(userDateOfBirth).format("D")
        : moment(new Date()).format("D")
    );
    setMonth(
      userDateOfBirth
        ? moment(userDateOfBirth).format("MMMM")
        : moment(new Date()).format("MMMM")
    );
    setYear(
      userDateOfBirth
        ? moment(userDateOfBirth).format("YYYY")
        : moment(new Date()).format("YYYY")
    );
    setUserGender(userData?.gender);
  };

  // console.log(dob);
  // console.log(day);
  // console.log(month);
  // console.log(year);
  // console.log(userGender);

  // console.log(days, month);

  useEffect(() => {
    getUserDetails();
  }, [userData]);
  useEffect(() => {
    convertMonthToNumber();
  }, [month]);

  useEffect(() => {
    setMonthAndDays();
    setDayAsPerMonthAndYear();
  }, []);

  const formValidation = () => {
    let isValid = true;
    if (!userGender) {
      isValid = false;
      setErrorMsg(true);
    }
    if (!day) {
      isValid = false;
      setErrorMsg(true);
    }
    if (!month) {
      isValid = false;
      setErrorMsg(true);
    }
    if (!year) {
      isValid = false;
      setErrorMsg(true);
    }
    return isValid;
  };

  const setProperMonth = (e) => {};

  const setProperDays = (e) => {};
  const todayDate = new Date();

  const setMonthAndDays = () => {
    const todayYear = todayDate.getFullYear();
    const todaysMonth = todayDate.getMonth();
    const monthSlice = monthsData.slice(0, todaysMonth + 1);
    setMonths(monthSlice);
  };

  const setDayAsPerMonthAndYear = () => {
    console.log("today date day", todayDate.getDate());
    const totalDaysUpDated = todayDate.getDate();

    setDays(totalDaysUpDated);
  };

  const skipStep = () => {
    if (location.search?.split("&")[0]?.split("=")[1] === "account") {
      dispatch(stepNavigate(nextStep));
    } else {
      dispatch(stepNavigate(nextStep));
    }
  };

  return (
    <div>
      <div className="vwContainer">
        {/* <div className="vwHeader">
					<div className="vwLogo">
						<img src={vwLogo} width="200px" alt="voltuswave logo" srcSet="" />
					</div>
				</div> */}
        <div className="vwContent">
          <div className="vwDetailsHead">
            <strong>Identity Details</strong>
            <br />
          </div>
          <div className="row radioFieldContainer">
            <div className="col  ">
              <label id="dobLabel">Date Of Birth *</label>
              <div className="dobContainer">
                <div>
                  <Select
                    onChange={(e) => {
                      setDay(e);
                    }}
                    value={day}
                    options={[...Array(days)].map((item, id) => ({
                      value: id + 1,
                      label: id + 1,
                    }))}
                  ></Select>
                  <div>
                    {errorMsg && !day && (
                      <span className="errorMsg">day is required</span>
                    )}
                  </div>
                </div>
                <div>
                  <Select
                    onChange={(e) => {
                      setMonth(e);
                      const todayDate = new Date();
                      console.log("todayDate.getMonth()", todayDate.getMonth());
                      console.log("monthNumber", months.indexOf(e));
                      console.log("year", year);
                      if (
                        todayDate.getMonth() === months.indexOf(e) &&
                        todayDate.getFullYear() == year
                      ) {
                        setDayAsPerMonthAndYear();
                      } else {
                        console.log(
                          "new Date(year, currentMonth, 0).getDate()",
                          currentMonth,
                          new Date(year, months.indexOf(e) + 1, 0).getDate()
                        );
                        setDays(
                          new Date(year, months.indexOf(e) + 1, 0).getDate()
                        );
                      }
                    }}
                    value={month}
                    options={months.map((item) => {
                      return { value: item, label: item };
                    })}
                  >
                    {/* <option disabled>Month</option> */}
                  </Select>
                  <div>
                    {errorMsg && !month && (
                      <span className="errorMsg">month is required</span>
                    )}
                  </div>
                </div>
                <div>
                  <Select
                    onChange={(e) => {
                      // setProperMonth(e)
                      setYear(e);
                      const todayDate = new Date();
                      console.log(
                        "lskafdlksdf",
                        e,
                        currentMonth,
                        new Date(year, currentMonth, 0).getDate()
                      );

                      if (todayDate.getFullYear() === e) {
                        setMonthAndDays();
                        setDays(todayDate.getDate());
                      } else {
                        console.log(";ladlkad", todayDate.getDate());
                        setDays(new Date(e, currentMonth, 0).getDate());
                        setMonths(monthsData);
                      }
                    }}
                    value={year}
                    options={[...Array(100)].map((item, id) => {
                      return {
                        value: years - id,
                        label: years - id,
                      };
                    })}
                  ></Select>
                  <div>
                    {errorMsg && !year && (
                      <span className="errorMsg">year is required</span>
                    )}
                  </div>
                </div>
              </div>
              {/* <label htmlFor="">Date of Birth</label><br />
              <input type="date"
                value={dob}
                onChange={(e) => { setDob(e.target.value) }}
              />
              <div>
              {errorMsg && !dob && <span className='errorMsg'>date of birth is required</span>}
              </div> */}
            </div>
          </div>
          <div className="row radioFieldContainer">
            <div className="col">
              <label>Gender *</label>
              <br />
              <div className="radioOptions">
                {/* <div className="radio"  > */}
                <label htmlFor="Male">
                  <input
                    type="radio"
                    id="Male"
                    value="Male"
                    checked={userGender === "Male"}
                    onChange={genderHandler}
                    onKeyPress={(event) => onkeyPressHandler(event, activeId)}
                  />{" "}
                  Male{" "}
                </label>
                {/* </div> */}
                {/* <div className="radio"> */}
                <label htmlFor="Female">
                  <input
                    type="radio"
                    id="Female"
                    value="Female"
                    checked={userGender === "Female"}
                    onChange={genderHandler}
                    onKeyPress={(event) => onkeyPressHandler(event, activeId)}
                  />{" "}
                  Female
                </label>
                {/* </div> */}
                {/* <div className="radio"> */}
                <label htmlFor="Others">
                  <input
                    type="radio"
                    id="Others"
                    value="Others"
                    checked={userGender === "Others"}
                    onChange={genderHandler}
                    onKeyPress={(event) => onkeyPressHandler(event, activeId)}
                  />{" "}
                  Others
                </label>
                {/* </div> */}
              </div>
              <div>
                {errorMsg && !userGender && (
                  <span className="errorMsg">Please select gender</span>
                )}
              </div>
            </div>
          </div>
          <div className="skipButtonContainer">
            {/* <button className="previousBtn" onClick={previousBtnHandler}>
              Previous
            </button> */}

            <button
              id="previousBtn"
              className="previousBtn"
              onKeyUp={skipStep}
              onClick={skipStep}
            >
              Skip
            </button>
            <button
              id="submitBtn"
              className="submitBtn"
              onKeyUp={submitHandler}
              onClick={submitHandler}
            >
              {loading ? loader(loading) : "Next"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
