import instanceAll from "./commonAxios";

export const getDetailsOfNonaccountSignUpService = async (data) => {
  try {
    const response = await instanceAll.post(
      "/getDetailsOfNonaccountSignUp",
      data
    );
    console.log("getting importUserdetails", response);
    if (response?.status === 200) {
      return {
        status: "success",
        data: response?.data,
      };
    } else {
      return {
        status: "failed",
        data: response?.data,
      };
    }
  } catch (error) {
    console.log(error);
  }
};
