import instanceAll from "./commonAxios";

export const EmailSignupService = async (data) => {
	try {
		const response = await instanceAll.post("/generateEmailOtp", data);
		// console.log("response:", response);
		if(response?.status === 200 && response?.data?.statusCode === 200){
           return {
			status :"success",
			data:response?.data
		   }
		}else{
           return {
			status :"failed",
			data :response?.data
		   }
		}
	} catch (error) {
		console.log(error);
	}
};

export const MobileSignupService = async (data) => {
	try {
		const response = await instanceAll.post("/generateMobileOtp", data);
		// console.log("response:", response);
		if(response?.status === 200){
			return {
			 status :"success",
			 data:response?.data
			}
		 }else{
			return {
			 status :"failed",
			 data :response?.data
			}
		 }
	} catch (error) {
		console.log(error);
	}
};

export const EmailExistService =async (data)=>{
	try {
		const response = await instanceAll.post("/existEmail", data);
		// console.log("response:", response);
		if(response?.status === 200){
			return {
			 status :"success",
			 data:response?.data
			}
		 }else{
			return {
			 status :"failed",
			 data :response?.data
			}
		 }
	} catch (error) {
		console.log(error);
	}
}

export const getGqlAccessDataHandler =async (data)=>{
	try {
		const response = await instanceAll.post("/getGqlData", data);
		console.log("getGqlAccessDataHandler response:", response);
		if(response?.status === 200){
			return {
			 status :"success",
			 data:response?.data
			}
		 }else{
			return {
			 status :"failed",
			 data :response?.data
			}
		 }
	} catch (error) {
		console.log(error);
	}
}
