import React, { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
import vwLogo from "../../assets/images/vw-logo.png";
import { useDispatch, useSelector } from "react-redux";
import { teamStrengthService } from "../../services/detailsApiServices";
import {
  teamSize,
  stepNavigate,
  mobileMandatory,
} from "../../features/userData/userDataSlice";
import { toast } from "react-toastify";
import backArrow from "../../assets/images/arrow.png";
import { loader, onkeyPressHandler } from "../../utilies/Utilies";
import { Switch } from "antd";

const TeamSize = ({nextStep}) => {
  // const navigate = useNavigate();
  const [team, setTeam] = useState("Only me");
  const [errorMsg, setErrorMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeId, setActiveId] = useState("submitBtn");
  const [isMobileMandatory, setIsMobileMandatory] = useState();

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  console.log(userData);

  console.log("isMobileMandatory:", isMobileMandatory);

  const mobileMandatoryHandler = (checked) => {
    console.log(checked);
    setIsMobileMandatory(checked);
  };

  const teamHandler = (e) => {
    setTeam(e.target.value);
  };
  // console.log(team);

  const submitHandler = async () => {
    try {
      if (formValidation()) {
        setLoading(true);

        let data = {
          leadId: userData?.leadId,
          teamSize: team,
          isMobileMandatory: isMobileMandatory ? "true" : "false",
          journeyIndex: 6,
        };
        const response = await teamStrengthService(data);
        // console.log(response);
        if (response?.status === "success" && response?.data?.addedTeamSize) {
          setLoading(false);
          dispatch(teamSize(team));
          dispatch(mobileMandatory(isMobileMandatory ? "true" : "false"));
          dispatch(stepNavigate(nextStep));

          // navigate("/createPassword");
        } else {
          setLoading(false);
          toast.error("something went wrong");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const previousBtnHandler = () => {
    dispatch(stepNavigate(2));
  };

  const getDetails = () => {
    if (userData) {
      setTeam(userData?.teamSize);
      setIsMobileMandatory(
        userData?.isMobileMandatory === "true" ? true : false
      );
    }
  };

  const formValidation = () => {
    let isValid = true;

    if (!team) {
      isValid = false;
      setErrorMsg(true);
    }
    return isValid;
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <div>
      <div className="vwContainer">
        {/* <div className="vwHeader">
					<div className="vwLogo">
						<img src={vwLogo} width="200px" alt="voltuswave logo" srcSet="" />
					</div>
				</div> */}
        <div className="vwContent">
          <div className="vwDetailsHead">
            <strong>Organization by team size</strong>
          </div>

          <div className="row radioFieldContainer">
            {/* <label>Select Your Team Size *</label> */}
            <div className="">
              <div className="radioOptions">
                {/* <div className="radio"> */}
                <label htmlFor="Only me">
                  <input
                    type="radio"
                    defaultChecked
                    value="Only me"
                    id="Only me"
                    checked={team === "Only me"}
                    onChange={teamHandler}
                    onKeyPress={(event) => onkeyPressHandler(event, activeId)}
                  />{" "}
                  Only me
                </label>
                {/* </div> */}
                {/* <div className="radio"> */}
                <label htmlFor="1-20">
                  <input
                    type="radio"
                    value="1-20"
                    id="1-20"
                    checked={team === "1-20"}
                    onChange={teamHandler}
                    onKeyPress={(event) => onkeyPressHandler(event, activeId)}
                  />{" "}
                  1-20
                </label>
                {/* </div> */}
                {/* <div className="radio"> */}
                <label htmlFor="21-50">
                  <input
                    type="radio"
                    value="21-50"
                    id="21-50"
                    checked={team === "21-50"}
                    onChange={teamHandler}
                    onKeyPress={(event) => onkeyPressHandler(event, activeId)}
                  />{" "}
                  21-50
                </label>
                {/* </div> */}
              </div>
              <div className="radioOptionsOne">
                {/* <div className="radio"> */}
                <label htmlFor="51-100">
                  <input
                    type="radio"
                    value="51-100"
                    id="51-100"
                    checked={team === "51-100"}
                    onChange={teamHandler}
                    onKeyPress={(event) => onkeyPressHandler(event, activeId)}
                  />{" "}
                  51-100
                </label>
                {/* </div> */}
                {/* <div className="radio"> */}
                <label htmlFor="100 ++">
                  <input
                    type="radio"
                    value="100 ++"
                    id="100 ++"
                    checked={team === "100 ++"}
                    onChange={teamHandler}
                    onKeyPress={(event) => onkeyPressHandler(event, activeId)}
                  />{" "}
                  100 ++
                </label>
                {/* </div> */}
              </div>
            </div>
            <div>
              {errorMsg && !team && (
                <span className="errorMsg">please select team</span>
              )}
            </div>
          </div>
          {/* <div style={{textAlign:"center" , marginTop:"20px"}} >
              <label style={{fontSize:"16px"}}>Mobile No for User Sign-up </label> :  <span style={{fontSize:"15px" , }}> Optional  <Switch value={isMobileMandatory} onChange={mobileMandatoryHandler} /> Mandatory </span>
          </div> */}
          <div className="skipButtonContainer">
            <button className="previousBtn" onClick={previousBtnHandler}>
              Previous
            </button>
            <button
              id="submitBtn"
              className="submitBtn"
              onKeyUp={submitHandler}
              onClick={submitHandler}
            >
              {loading ? loader(loading) : "Next"}
            </button>
            {/* <Link className="backBtn" to="/details">
							<img
								src={backArrow}
								srcSet=""
								style={{ height: 15 }}
								className="backArrow"
							/>{" "}
							Back
						</Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamSize;
