import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { useSelector, useDispatch } from "react-redux";
// import { useNavigate, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import vwLogo from "../../assets/images/vw-logo.png";
import { mobileOtpVerifyService } from "../../services/otpVerifyApiServices";
import { resendMobileOtpService } from "../../services/resendOtpApiServices";
import {
  stepNavigate,
  isMobileNumberVerified,
} from "../../features/userData/userDataSlice";
import backArrow from "../../assets/images/arrow.png";
import { loader } from "../../utilies/Utilies";
import { useLocation } from "react-router-dom";

const OtpVerify = ({nextStep}) => {
  // const navigate = useNavigate();
  const location = useLocation();
  const userData = useSelector((state) => state.userData);
  console.log("state in mobile verify:", userData);

  const [otp, setOtp] = useState(null);
  const [errorMsg, setErrorMsg] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState();
  const [color, setColor] = useState();
  const [counter, setCounter] = useState(30);
  const [loading, setLoading] = useState(false);

  const [timeRemaining, setTimeRemaining] = useState(10 * 60); // 10 minutes in seconds
  const [isResendEnabled, setIsResendEnabled] = useState(false);

  let intervalId;
  useEffect(() => {
    if (timeRemaining > 0) {
      intervalId = setInterval(() => {
        setTimeRemaining((time) => time - 1);
        if (timeRemaining <= 60 * 9) {
          setIsResendEnabled(true);
        }
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timeRemaining]);

  function handleResendClick() {
    resendRequest();
  }

  const dispatch = useDispatch();
  const changeHandler = (value) => {
    console.log("otp value", value.length);
    setOtp(value);
  };

  const submitHandler = async () => {
    try {
      if (formValidation()) {
        setLoading(true);
        let data = {
          leadId: userData?.leadId,
          leadMobile: userData?.mobile,
          leadOtp: Number(otp),
          journeyIndex: 4,
        };
        const response = await mobileOtpVerifyService(data);
        console.log("mobile response:", response);
        if (response?.status === "success") {
          setLoading(false);
          if (response?.data?.isOtpCorrect) {
            if (location.search?.split("&")[0]?.split("=")[1] === "account") {
              dispatch(stepNavigate(nextStep));
            } else {
              dispatch(stepNavigate(nextStep));
            }
            // navigate("/mobileOtpSuccess");
          }
        } else {
          setLoading(false);
          setOtp(null);
          toast.error(response.error.message);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const backbtnHandler = () => {
    dispatch(isMobileNumberVerified(false));
  };

  const resendRequest = async () => {
    setOtp(null);
    clearInterval(intervalId);
    setIsResendEnabled(false);
    try {
      let data = {
        leadId: userData?.leadId,
        leadMobile: userData?.mobile,
      };

      const response = await resendMobileOtpService(data);
      // console.log("resend mobile otp", response);
      setTimeRemaining(10 * 60); // reset timer to 10 minutes
      if (
        response?.status === "success" &&
        response?.data?.statusCode === 200 &&
        response?.data?.dbResponse?.isMobileOtpSend
      ) {
        // toast.success("OTP Resended Succesfully");
        setOtp(null);
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formValidation = () => {
    let isValid = true;
    if (otp?.length !== 4) {
      isValid = false;
      setErrorMsg(true);
    }
    return isValid;
  };

  const previousBtnHandler=()=>{
    dispatch(stepNavigate(1))
  }

  useEffect(() => {
    {
      otp?.length == 4 ? setBackgroundColor("#2971D9") : setBackgroundColor();
    }
    {
      otp?.length == 4 ? setColor("white") : setColor();
    }
    if (otp?.length == 4) {
      document.getElementById("verifyBtn").focus();
    }
  }, [otp]);

  // useEffect(() => {
  //   const timer =
  //     counter > 0 &&
  //     setInterval(() => {
  //       setCounter(counter - 1);
  //     }, 1000);
  //   return () => clearInterval(timer);
  // }, [counter]);

  return (
    <div>
      <div className="vwContainer">
        {/* <div className="vwHeader">
          <div className="vwLogo">
            <img src={vwLogo} width="200px" alt="voltuswave logo" srcSet="" />
          </div>
        </div> */}
        <div className="vwContent">
          <div className="vwOTPHeadText">
            <strong>Confirm OTP</strong>
            <br />
            <small className="text">
              Enter OTP just sent to{" "}
              <span style={{ color: "#868585", fontWeight: "bold" }}>
                {userData?.code + " " + userData?.mobile}
              </span>
            </small>
          </div>
          <div className="row">
            <div className="otpContainer">
              <OtpInput
                value={otp}
                onChange={changeHandler}
                numInputs={4}
                separator={<span></span>}
                inputStyle="inputOtp"
                isInputNum={true}
                shouldAutoFocus={true}
              />
              {/* <div>
                {errorMsg && otp?.length !== 4 && (
                  <span className="errorMsg">OTP is required</span>
                )}
              </div> */}
            </div>
          </div>
          <div className="buttonContainer">
          <button className="previousBtn" onClick={backbtnHandler}>
              Previous
            </button>
            <button
              id="verifyBtn"
              className="verifyBtn"
              style={{ background: backgroundColor, color: color }}
              // onClick={submitHandler}
              onFocus={submitHandler}
            >
              {loading ? loader(loading) : "Verify"}
            </button>
          </div>
          {/* <Link className="backBtn" to="/mobileSignup">
              <img
                src={backArrow}
                srcSet=""
                style={{ height: 15 }}
                className="backArrow"
              />{" "}
              Back
            </Link> */}
          {/* <div className="resendContaier">
            <span style={{ color: "#888888" }}>
              If you didn't receive an OTP?{" "}
              {counter ? (
                <span className="counterText">
                  Resend in <span className="counter">[00:{counter}]</span>
                </span>
              ) : (
                <a onClick={resendRequest}>Resend OTP</a>
              )}
            </span>
          </div> */}
          <div className="resendContainer">
            <p>
              OTP will expire in{" "}
              <span className="time">
                {Math.floor(timeRemaining / 60)}:
                {timeRemaining % 60 < 10
                  ? `0${timeRemaining % 60}`
                  : timeRemaining % 60}
              </span>
            </p>
            <div>
              If you did not receive OTP, click
              {isResendEnabled ? (
                <p onClick={handleResendClick} className="resendActiveButton">
                  Resend OTP
                </p>
              ) : (
                <p>Resend OTP</p>
              )}{" "}
            </div>
          </div>
          {/* <button className="back-btn" onClick={backbtnHandler}>
            Back
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default OtpVerify;
