import React, { useEffect, useState } from "react";

import { PublicClientApplication } from "@azure/msal-browser";

import { MsalProvider, useMsal } from "@azure/msal-react";

import styled from "styled-components";

import microsoft from "../../assets/images/microsoft.png";

import {
  getGeoApiLocation,
  loader,
  onkeyPressHandler,
} from "../../utilies/Utilies";

import { useDispatch, useSelector } from "react-redux";
import {
  email,
  firstName,
  lastName,
  leadId,
  stepNavigate,
  isSocialLogin,
} from "../../features/userData/userDataSlice";
import { EmailSignupService } from "../../services/signupApiService";
import {
  existEmailCheckingService,
  existEmailCheckingServiceInVwp,
} from "../../services/existEmailAndMobileService";
import { buildConstants } from "../../utilies/buildConstants";

const SocialButton = styled.button`
  background-color: #fff;

  border: 1px solid #dadce0;

  border-radius: 4px;

  cursor: pointer;

  font-family: "Google Sans", arial, sans-serif;

  font-size: 15px;
  line-height: 1.5;

  font-weight: 500;

  display: flex;

  align-items: center;
  margin-top: 10px;
  justify-content: center;
  flex-grow: 1;
  padding: 7px 13px;
  color: #3c4043;
  width: 306px;
  &:hover {
    background-color: #f7fafc;
    border-color: #dcecf7;
  }
`;

const MicrosoftIcon = styled.img`
  height: 16px;
  width: 16px;
`;

const ButtonText = styled.span`
  // margin-right: 20px; /* Add 10px of margin to the left of the text */
  margin-left: 17px;
  flex-grow: 6;
  color: #1e1e1f;
`;

function MicrosoftSignup(props) {
  const { setIsEmailExist, setshowSignInUrl } = props;
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  const [countryCode, setCountryCode] = useState();
  const [type, setType] = useState("email");

  useEffect(() => {
    getGeoApiLocation(setCountryCode, type,userData.ipnfoToken);
  }, [userData]);

  const { microsoftClientId, microsoftRedirectUrl } = buildConstants;

  console.log("buildContsnts", buildConstants.microsoftRedirectUrl);
  const msalConfig = {
    auth: {
      clientId: buildConstants.microsoftClientId,

      authority: "https://login.microsoftonline.com/common",

      redirectUri: buildConstants.microsoftRedirectUrl,
    },
  };

  //*********************************************************************************************************************** */

  const msalInstance = new PublicClientApplication(msalConfig);

  function MicrosoftLoginButton() {
    const { instance } = useMsal();

    const handleLogin = async () => {
      const response = await instance.loginPopup({
        scopes: ["openid", "profile", "email", "user.read"],

        prompt: "select_account",
      });

      const graphResponse = await fetch("https://graph.microsoft.com/v1.0/me", {
        headers: {
          Authorization: `Bearer ${response.accessToken}`,
        },
      });

      const userProfile = await graphResponse.json();

      console.log("User name:", userProfile.displayName);

      console.log(
        "User email:",
        userProfile.mail || userProfile.userPrincipalName
      );
      const name = userProfile.displayName.split(" ");
      const emailCheck = await emailChecking(userProfile.mail);
      if (emailCheck === true) {
        submitHandler(name[0], name[1], userProfile.mail);
        dispatch(email(userProfile.mail));
        dispatch(firstName(name[0]));
        dispatch(lastName(name[1]));
        dispatch(isSocialLogin(true));
        dispatch(stepNavigate(1));
      }
    };

    return (
      <SocialButton onClick={handleLogin}>
        <MicrosoftIcon src={microsoft} alt="Microsoft" />

        <ButtonText>Continue with Microsoft</ButtonText>
      </SocialButton>
    );
  }

  //////////////////////////////////////////////////////////////////////////////

  function AuthenticationHandler() {
    const { instance } = useMsal();

    useEffect(() => {
      instance.handleRedirectPromise().then((response) => {
        if (response !== null && response.account !== null) {
          const account = response.account;

          console.log("Account name:", account.name);

          console.log("Account email:", account.username);
        }
      });
    }, []);

    return null;
  }

  /////////////////////////////////////////////////////////////////////////////////
  const emailChecking = async (userEmail) => {
    try {
      const response = await existEmailCheckingServiceInVwp({
        leadEmail: userEmail,
        endPoint: userData.endPoint,
      });
      console.log("vwpemailexist", response);
      if (response.data.available === true) {
        existEmailCheckingService({ leadEmail: userEmail })
          .then((response) => {
            console.log(
              "email exist response",
              response.data.isEmailExisted,
              response.status
            );
            if (
              response?.status === "success" &&
              response?.data?.isEmailExisted === true
            ) {
              setIsEmailExist(true);
              setshowSignInUrl(true);
              return false;
            } else {
              setIsEmailExist(false);
              return true;
              console.log("else");
            }
          })
          .catch((error) => {
            console.log("catch");
            console.log(error);
            setIsEmailExist(false);
          });
      } else {
        setIsEmailExist(true);
        setshowSignInUrl(true);
        return false;
      }
    } catch (error) {
      setIsEmailExist(false);
      console.log("else");
    }
  };

  const submitHandler = async (userFirstName, userLastName, userEmail) => {
    try {
      if (true) {
        //!isEmailExist
        // setLoading(true);
        let data = {
          firstName: userFirstName,
          lastName: userLastName,
          emailId: userEmail,
          campaignId: 68576,
          sourceId: 1,
          journeyIndex: 1,
          countryCode: countryCode,
          isSocialLogin: true,
        };
        // setIsEmailVerified(true);
        const response = await EmailSignupService(data);
        console.log("email signup response", response);
        if (response?.status === "success") {
          // setLoading(false);
          dispatch(firstName(response?.data?.data?.firstName));
          dispatch(lastName(response?.data?.data?.lastName));
          dispatch(leadId(response?.data?.data?.leadId));
          dispatch(email(response?.data?.data?.leadEmail));
          // dispatch(stepNavigate(2))
          // navigate("/emailOtpVerify");
        } else {
          // setLoading(false);
          // toast.error("something went wrong");
        }
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <MsalProvider instance={msalInstance}>
      <div>
        <MicrosoftLoginButton />

        <AuthenticationHandler />
      </div>
    </MsalProvider>
  );
}

export default MicrosoftSignup;
