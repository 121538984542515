import { Spin } from "antd";
import axios from "axios";
import Lottie from "lottie-react";
import successLoader from "../assets/animations/loading-success.json";
import loaderBalls from "../assets/animations/loading-balls.json";
import invalidPage from "../assets/animations/invalidPage.json";

export const loader = (loading) => {
  return <Spin spinning={loading} className="loader" />;
};

export const lottieConstants = {
  successLoader: successLoader,
  loaderBalls: loaderBalls,
  invalidPage: invalidPage,
};

export const LottieLoaders = (props) => {
  return (
    <Lottie
      animationData={props.lottieConstant}
      style={{ marginTop: "220px", height: "110px" }}
      autoPlay={true}
      loop={true}
    />
  );
};

// gettig location details through geolocation api:
export const getGeoApiLocation = async (setCountryCode, type,token) => {
  try {
    const response = await axios.get(
      `https://ipinfo.io/json?token=${token}`
    );
    // console.log("geo location Api response :", response);
    if (response?.data) {
      setCountryCode(response?.data?.country);
      console.log(response, "location");
      // if(type === "email"){
      //   setCountryCode(response?.data?.country)
      // }else{
      //   if (response?.data?.country === "IN") {
      //     setCountryCode("+91")
      //   }
    }
  } catch (error) {
    console.log(error);
  }
};

export const onkeyPressHandler = (event, id) => {
  // console.log("event:", event, id);
  if (event.key === "Enter") {
    document.getElementById(id).focus();
  }
};
