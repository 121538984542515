import React, { memo, useMemo, useState, useEffect } from "react";
import { Modal } from "antd";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import "./App.css";
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";
import "./styles/App.scss";
import { useDispatch, useSelector } from "react-redux";
import ModalComponent from "./pages/ModalComponent";
import { ToastContainer } from "react-toastify";
import { useLazyQuery, gql, useMutation } from "@apollo/client";

function App() {
	const { stepNumber } = useSelector((state) => state.userData);
	console.log("redux", stepNumber);

	return (
		<div className="App">
			<ToastContainer position="top-center" autoClose={2000} />
			<Router>
				<Routes>
					<Route
						path="/"
						element={<ModalComponent stepNumber={stepNumber} />} // removed from modal not in modal component
					/>
				</Routes>
			</Router>
		</div>
	);
}

export default App;
