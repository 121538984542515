import React, { useEffect, useState } from "react";
import { fetchSchemaDetailsService } from "../../services/fetchSchemaDetailsService";
import { useDispatch, useSelector } from "react-redux";
import {
  orgJoinOrgDetails,
  orgName,
  schemaDetails,
  subdomainCode,
  vwpOrgId,
} from "../../features/userData/userDataSlice";
import TeamSize from "../completeDetails/TeamSize";
import CreatePassword from "../completeDetails/CreatePassword";
import Details from "../completeDetails/Details";

import MobileSignUp from "../mobileVerify/MobileSignUp";
import EmailSignUp from "../emailVerfy/EmailSignup";
import { Skeleton, Steps } from "antd";

export default function OrgJoinBySchemaId({
  schemaId,
  stepNumber,
  imageUrl,
  signupType,
}) {
  const { signupTypeInUrl } = useSelector((state) => state);
  const [signUpSchemaDetails, setSignUpSchemaDetails] = useState({});
  const dispatch = useDispatch();
  console.log("signupTypeInUrl", signupTypeInUrl);
  console.log("schemeId in orgJoinHomeComponent", schemaId);
  console.log("signupTypehere", signupType);
  const fetchSchemaDetails = async () => {
    const payload = { schemaId: schemaId, orgJoinType: signupType };
    try {
      const fetchSchemaDetailsResponse = await fetchSchemaDetailsService(
        payload
      );
      const { _org_code, _org_id, _org_name,_vwp_org_id } =
        fetchSchemaDetailsResponse.data.data[0];
      setSignUpSchemaDetails(
        fetchSchemaDetailsResponse.data.data[0].result_json
      );
      dispatch(
        schemaDetails(fetchSchemaDetailsResponse.data.data[0].result_json)
      );
      dispatch(
        orgJoinOrgDetails({ orgName: _org_name, subdomainCode: _org_code, vlsOrgId: _org_id ,vwpOrg_id:_vwp_org_id})
      );
      dispatch(vwpOrgId(_vwp_org_id))
      dispatch(subdomainCode(_org_code))
      dispatch(orgName(_org_name))
      console.log("fetchSchemaDetailsResponse", fetchSchemaDetailsResponse);
    } catch (err) {
      console.log("err in orgJoinHomeComponent", err);
    }
  };
  useEffect(() => {
    fetchSchemaDetails();
  }, []);

  const SignUpComponents = ({ stepNumber }) => {
    console.log("signUpSchemaDetails", signUpSchemaDetails);
    console.log("stepNumber", stepNumber);
    let compoment;

    if (signUpSchemaDetails.signupSetting == "Email and Mobile Mandatory") {
      compoment = (
        <div>
          <Steps
            className="signup-steps"
            size="small"
            current={stepNumber}
            responsive={false}
            items={[{}, {}, {}, {}]}
          />
          {stepNumber == 0 && <EmailSignUp isOptional={false} nextStep={1} />}
          {stepNumber == 1 && <MobileSignUp isOptional={false} nextStep={2} />}
          {stepNumber == 2 && <Details nextStep={3} />}
          {stepNumber == 3 && <CreatePassword orgJoinType={signupType} />}
        </div>
      );
    } else if (
      signUpSchemaDetails.signupSetting == "Email Mandatory and Mobile Optional"
    ) {
      compoment = (
        <div>
          <Steps
            className="signup-steps"
            size="small"
            current={stepNumber}
            responsive={false}
            items={[{}, {}, {}, {}]}
          />
          {stepNumber == 0 && <EmailSignUp isOptional={false} nextStep={1} />}
          {stepNumber == 1 && <MobileSignUp isOptional={true} nextStep={2} />}
          {stepNumber == 2 && <Details nextStep={3} />}
          {stepNumber == 3 && <CreatePassword orgJoinType={signupType} />}
        </div>
      );
    } else if (
      signUpSchemaDetails.signupSetting == "Mobile Mandatory and Email Optional"
    ) {
      compoment = (
        <div>
          <Steps
            className="signup-steps"
            size="small"
            current={stepNumber}
            responsive={false}
            items={[{}, {}, {}, {}]}
          />
          {stepNumber == 0 && <EmailSignUp isOptional={true} nextStep={1} />}
          {stepNumber == 1 && (
            <MobileSignUp
              isOptional={false}
              isEmailOptional={true}
              nextStep={2}
            />
          )}
          {stepNumber == 2 && <Details nextStep={3} />}
          {stepNumber == 3 && <CreatePassword orgJoinType={signupType} />}
        </div>
      );
    } else if (signUpSchemaDetails.signupSetting == "Email Only") {
      compoment = (
        <div>
          <Steps
            className="signup-steps"
            size="small"
            current={stepNumber}
            responsive={false}
            items={[{}, {}, {}, {}]}
          />
          {stepNumber == 0 && <EmailSignUp nextStep={1} />}
          {stepNumber == 1 && <Details nextStep={2} />}
          {stepNumber == 2 && <CreatePassword orgJoinType={signupType} />}
        </div>
      );
    } else if (signUpSchemaDetails.signupSetting == "Mobile Only") {
      console.log("Mobile Only");
      // stepNumber=1
      compoment = (
        <div>
          <Steps
            className="signup-steps"
            size="small"
            current={stepNumber}
            responsive={false}
            items={[{}, {}, {}, {}]}
          />

          {stepNumber == 0 && (
            <MobileSignUp nextStep={1} emailRequired={false} />
          )}
          {stepNumber == 1 && <Details nextStep={2} />}
          {stepNumber == 2 && <CreatePassword orgJoinType={signupType} />}
        </div>
      );
    }
    return compoment;
  };

  console.log("signupTypeInUrl", signupTypeInUrl);
  return (
    <div style={{ height: "100vh" }}>
      <div className="modal">
        <div className="vwContainer">
          <div className="vwHeader">
            <div className="vwLogo">
              {imageUrl && (
                <img
                  src={imageUrl}
                  width="200px"
                  alt="compaign logo"
                  srcSet=""
                />
              )}
              {!imageUrl && (
                <div style={{ width: "200px", height: "38px" }}>
                  <Skeleton.Input active={true} size="200px" block={true} />
                </div>
              )}
            </div>
          </div>
          <SignUpComponents stepNumber={stepNumber} />
        </div>
        {/* </Modal> */}
      </div>
    </div>
  );
}
