import React, { Component, memo, useEffect, useState } from "react";
import { fetchSchemaDetailsService } from "../../services/fetchSchemaDetailsService";
import { useDispatch, useSelector } from "react-redux";
import { EmailSignupService } from "../../services/signupApiService";
import TeamSize from "../completeDetails/TeamSize";
import CreatePassword from "../completeDetails/CreatePassword";
import Details from "../completeDetails/Details";

import MobileSignUp from "../mobileVerify/MobileSignUp";
import EmailSignUp from "../emailVerfy/EmailSignup";
import { Skeleton, Steps } from "antd";
import { getEndPointByTldIdService } from "../../services/getEndPointByTldIdService";
import { endPoint, schemaDetails, tldId } from "../../features/userData/userDataSlice";

function NewOrgCreationBySchemaId({ schemaId, stepNumber, imageUrl,signupType }) {
  const { signupTypeInUrl } = useSelector((state) => state);
  const [signUpSchemaDetails, setSignUpSchemaDetails] = useState({});
  const [tldStatus, setTldstatus] = useState(true);
  const dispatch = useDispatch();

  console.log("signupTypeInUrl", signupTypeInUrl);
  console.log("schemeId in orgJoinHomeComponent", schemaId);
  const fetchSchemaDetails = async () => {
    const payload = { schemaId: schemaId };
    try {
      const fetchSchemaDetailsResponse = await fetchSchemaDetailsService(
        payload
      );
      setSignUpSchemaDetails(
        fetchSchemaDetailsResponse.data.data[0].vls_get_schemes_list
      );
      dispatch(schemaDetails(fetchSchemaDetailsResponse.data.data[0].vls_get_schemes_list))
      console.log("fetchSchemaDetailsResponse", fetchSchemaDetailsResponse);
    } catch (err) {
      console.log("err in orgJoinHomeComponent", err);
    }
  };
  useEffect(() => {
    fetchSchemaDetails();
  }, []);

  const userData = useSelector((state) => state.userData);
  const enryll=userData?.schemaDetails?.appWithEntryPoints&&userData?.schemaDetails?.appWithEntryPoints[0]?.entryPoints?.vwp_entry_point_id
  console.log("qqqqq",enryll)

  const SignUpComponents = ({ stepNumber }) => {
    console.log("signUpSchemaDetails", signUpSchemaDetails);
    console.log("stepNumber", stepNumber);
    let compoment;

    if (signUpSchemaDetails.signupSetting == "Email and Mobile Mandatory") {
      compoment = (
        <div>
          <Steps
            className="signup-steps"
            size="small"
            current={stepNumber}
            responsive={false}
            items={[{}, {}, {}, {}, {}]}
          />

          {stepNumber == 0 && <EmailSignUp isOptional={false} nextStep={1} />}
          {stepNumber == 1 && <MobileSignUp isOptional={false} nextStep={2} />}
          {stepNumber == 2 && <Details nextStep={3} />}
          {stepNumber == 3 && <TeamSize nextStep={4} />}
          {stepNumber == 4 && <CreatePassword orgJoinType={signupType}/>}
        </div>
      );
    } else if (
      signUpSchemaDetails.signupSetting == "Email Mandatory and Mobile Optional"
    ) {
      compoment = (
        <div>
          <Steps
            className="signup-steps"
            size="small"
            current={stepNumber}
            responsive={false}
            items={[{}, {}, {}, {}, {}]}
          />
          {stepNumber == 0 && <EmailSignUp isOptional={false} nextStep={1} />}
          {stepNumber == 1 && <MobileSignUp isOptional={true} nextStep={2} />}
          {stepNumber == 2 && <Details nextStep={3} />}
          {stepNumber == 3 && <TeamSize nextStep={4} />}
          {stepNumber == 4 && <CreatePassword orgJoinType={signupType}/>}
        </div>
      );
    } else if (
      signUpSchemaDetails.signupSetting == "Mobile Mandatory and Email Optional"
    ) {
      compoment = (
        <div>
          <Steps
            className="signup-steps"
            size="small"
            current={stepNumber}
            responsive={false}
            items={[{}, {}, {}, {}, {}]}
          />
          {stepNumber == 0 && <EmailSignUp isOptional={true} nextStep={1} />}
          {stepNumber == 1 && (
            <MobileSignUp
              isOptional={false}
              isEmailOptional={true}
              nextStep={2}
            />
          )}
          {stepNumber == 2 && <Details nextStep={3} />}
          {stepNumber == 3 && <TeamSize nextStep={4} />}
          {stepNumber == 4 && <CreatePassword orgJoinType={signupType}/>}
        </div>
      );
    } else if (signUpSchemaDetails.signupSetting == "Email Only") {
      compoment = (
        <div>
          <Steps
            className="signup-steps"
            size="small"
            current={stepNumber}
            responsive={false}
            items={[{}, {}, {}, {}]}
          />
          {stepNumber == 0 && <EmailSignUp nextStep={1} />}
          {stepNumber == 1 && <Details nextStep={2} />}
          {stepNumber == 2 && <TeamSize nextStep={3} />}
          {stepNumber == 3 && <CreatePassword orgJoinType={signupType}/>}
        </div>
      );
    } else if (signUpSchemaDetails.signupSetting == "Mobile Only") {

      console.log("Mobile Only")
      // stepNumber=1
      compoment = (
        <div>
          <Steps
            className="signup-steps"
            size="small"
            current={stepNumber}
            responsive={false}
            items={[{}, {}, {}, {}]}
          />

          {stepNumber == 0 && <MobileSignUp nextStep={1} emailRequired={false}/>}
          {stepNumber == 1 && <Details nextStep={2} />}
          {stepNumber == 2 && <TeamSize nextStep={3} />}
          {stepNumber == 3 && <CreatePassword orgJoinType={signupType}/>}
        </div>
      );
    }
    return compoment;
  };

  console.log("signupTypeInUrl", signupTypeInUrl);
  return (
    <div style={{height:"100vh"}}>
      <div className="modal">
        <div className="vwContainer">
          <div className="vwHeader">
            <div className="vwLogo">
              {imageUrl && (
                <img
                  src={imageUrl}
                  width="200px"
                  alt="compaign logo"
                  srcSet=""
                />
              )}
              {!imageUrl && (
                <div style={{ width: "200px", height: "38px" }}>
                  <Skeleton.Input active={true} size="200px" block={true} />
                </div>
              )}
            </div>
          </div>
          <SignUpComponents stepNumber={stepNumber} />
        </div>
        {/* </Modal> */}
      </div>
    </div>
  );
}

export default memo(NewOrgCreationBySchemaId);
