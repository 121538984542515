import instanceAll from "./commonAxios";

export const getSocialSignupDetailsService = async (tld, subdomain) => {
  try {
    console.log("orgservice");
    const data = {
      orgCode: subdomain,
      tld: tld,
    };
    const response = await instanceAll.post("/getSocialSignupDetails", data);
    console.log("orgdetails", response);
    if (response?.status === 200) {
      return {
        status: "success",
        data: response?.data,
      };
    } else {
      return {
        status: "failed",
        data: response?.data,
      };
    }
  } catch (error) {
    console.log(error);
  }
};