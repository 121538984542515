import instanceAll from "./commonAxios";

export const getEndPointByTldIdService = async (data) => {
  try {
    const response = await instanceAll.post("/getEndPointByTldId", data);
    console.log("getting tldId", response);
    if (response?.status === 200) {
      return {
        status: "success",
        data: response?.data,
      };
    } else {
      return {
        status: "failed",
        data: response?.data,
      };
    }
  } catch (error) {
    console.log(error);
  }
};
