import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import vwLogo from "../../assets/images/vw-logo.png";
import viewPswd from "../../assets/images/show.png";
import hidePswd from "../../assets/images/hide.png";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import Information from "../../assets/images/information.png";
import { Spin, Tooltip } from "antd";
import { createPasswordService } from "../../services/detailsApiServices";
import { toast } from "react-toastify";
import { LottieLoaders, loader, lottieConstants } from "../../utilies/Utilies";
import { firstName, stepNavigate } from "../../features/userData/userDataSlice";
import axios from "axios";
import { sendOrgDetailsService } from "../../services/sendOrgDetailsService";
import {
  checkOrgNameService,
  isOrgExistService,
} from "../../services/isOrgExistService";
import { createSubcriptionInStripeService } from "../../services/createStripeSubscripiton";
import { useLazyQuery, gql, useMutation } from "@apollo/client";

const CreatePassword = ({ orgJoinType }) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  console.log("createPassLastStep", userData);

  const domainName = window.location.host;
  const subdomain = window.location.host.split(".")[0];
  const domainURL = window.location.host.split(".")[1];
  const topLevelDomain = window.location.host.split(".")[2];

  const domain = `${domainURL}.${topLevelDomain}`;

  const appId = "b2e1a6ca-d675-4d36-94bd-242d7d6cbecb";
  // console.log("query params :", appId);

  //   console.log(`logging hostName :`,domainName)
  console.log(`logging subdomain :`, subdomain);
  console.log("lastApi");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [orgName, setOrgName] = useState("");
  const [subDomain, setSubDomain] = useState("");
  const [showPswd, setShowPswd] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);
  const [check, setCheck] = useState(false);
  const [passwordCheck, setPasswordCheck] = useState(false);
  const [strongPswd, setStrongPswd] = useState(false);
  const [showConfirmPswd, setShowConfirmPswd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDomain, setIsDomain] = useState(false);
  const [vlsOrgId, setVlsOrgId] = useState();
  const [disableSubmitButton, setSubmitButtonState] = useState(false);
  const [isOrgNameExist, setIsOrgNameExist] = useState(false);
  const location = useLocation();

  console.log("search", location.search);
  const recaptchaHandler = (value) => {
    // console.log("recaptcha value:", value);
    setCaptcha(value);
  };
  // console.log("captcha value ===", captcha);

  //checking user type
  const signupType = location.search?.split("&")[0]?.split("=")[1];
  const isAccount = signupType === "account" || orgJoinType == "signupSchemeID";

  //getting th query params
  const entryPoint = location.search?.split("&")[2]?.split("=")[1];
  const tld = userData.tldId; //location.search?.split("&")[1]?.split("=")[1];
  const entryPointId = isAccount
    ? location.search?.split("&")[1]?.split("=")[1]
    : location.search?.split("&")[2]?.split("=")[1];

  console.log("entryPoint", entryPointId);

  // checking user enable chat is exist or not
  const [userIdExist, userIdExistResponse] = useLazyQuery(
    gql`
      query ($user_id: ID!, $origin_id: String!) {
        userIDExists(user_id: $user_id, origin_id: $origin_id)
      }
    `
  );
  // console.log({isAccount});

  // adding user chat enable
  const [addUserForChatEnable, addUserForChatEnableResponse] = useMutation(
    gql`
      mutation (
        $user_name: String!
        $user_id: ID!
        $org_id: String!
        $tld_id: String!
      ) {
        addUser(
          input: {
            user_name: $user_name
            user_id: $user_id
            org_id: $org_id
            tld_id: $tld_id
          }
        ) {
          user_id
          user_name
        }
      }
    `
  );

  const [addOrgDetails, addOrgDetailsResponse] = useMutation(
    gql`
      mutation MyMutation(
        $org_id: ID!
        $tld_id: String!
        $org_name: String!
        $short_description: String
        $long_description: String
        $image_id: String
        $tags: [String]
      ) {
        addOrg(
          input: {
            org_id: $org_id
            tld_id: $tld_id
            org_name: $org_name
            short_description: $short_description
            long_description: $long_description
            image_id: $image_id
            tags: $tags
          }
        ) {
          image_id
          long_description
          org_name
          org_id
          origin_id
          short_description
          tags
          tld_id
        }
      }
    `
  );

  useEffect(() => {
    console.log({ userIdExistResponse });
  }, [userIdExistResponse]);

  //submitHandler
  const submitHandler = async () => {
    try {
      const ValidationResp = await formValidation();
      if (ValidationResp && password === confirmPassword) {
        setLoading(true);
        let data;
        if (orgJoinType == "signupSchemeID") {
          const entryPointOfSingupSchemaID =
            userData?.schemaDetails?.appWithEntryPoints &&
            userData?.schemaDetails?.appWithEntryPoints[0]?.entryPoints
              ?.vwp_entry_point_id;
              
              let signupType="account"

          data = {
            leadId: userData?.leadId,
            leadOrganisation: orgName === "" ? userData.orgName : orgName,
            leadPassword: confirmPassword,
            leadSubdomainCode:
              subDomain === "" ? userData.subdomainCode : subDomain,
            captcha: captcha,
            journeyIndex: 9,
            isAccount: true ,
            userData: userData,
            location: `${`?signupType=${signupType}&tldId=${tld}&entryPointId=${entryPointOfSingupSchemaID}`}`,
            entryPointId: entryPointOfSingupSchemaID,
            tldId: tld,
          };
        } else if (orgJoinType == "orgJoinSchemeID") {
          const entryPointOfSingupSchemaID =
            userData?.schemaDetails?.appWithEntryPoints &&
            userData?.schemaDetails?.appWithEntryPoints[0]?.entryPoints
              ?.vwp_entry_point_id;
              let signupType="invite"
          data = {
            leadId: userData?.leadId,
            leadOrganisation:userData.orgJoinOrgDetails.orgName,
            leadPassword: confirmPassword,
            leadSubdomainCode:
              userData.orgJoinOrgDetails.subdomainCode, 
            captcha: captcha,
            journeyIndex: 9,
            isAccount: false,
            userData: userData,
            location: `${`?signupType=${signupType}&tldId=${tld}&entryPointId=${entryPointOfSingupSchemaID}`}`,
            entryPointId: entryPointOfSingupSchemaID,
            tldId: tld,
          };
        } else {

          data = {
            leadId: userData?.leadId,
            leadOrganisation: orgName === "" ? userData.orgName : orgName,
            leadPassword: confirmPassword,
            leadSubdomainCode:
              subDomain === "" ? userData.subdomainCode : subDomain,
            captcha: captcha,
            journeyIndex: 9,
            isAccount: isAccount ? true : false,
            userData: userData,
            location: `${
              isAccount
                ? `?signupType=${signupType}&tldId=${tld}&entryPointId=${entryPointId}`
                : `?signupType=${signupType}&tldId=${tld}${
                    entryPointId === undefined || entryPointId === null
                      ? ""
                      : "" //`&entryPointId=${entryPointId}`
                  }`
            }`,
            entryPointId: isAccount && entryPointId,
            tldId: tld,
          };
        }

        // const vlsResponse = await createPasswordService(data);
        const stepFunctionResponse = await createPasswordService(data);

        // setVlsOrgId(response.data.dbResponse.vlsOrgId);
        console.log("vls first api res", stepFunctionResponse);
        if (stepFunctionResponse.status === "success") {
          const output = JSON.parse(stepFunctionResponse.data.output);
          // const body=JSON.parse(output);
          console.log(" stepFunctionOutput", output);
          setLoading(false);
          if (output.body.isAccount) {
            const loginLink = `https://${output.body.subDomainCode}.${output.body.tld}/accountlogin.html`;
            window.location.href = loginLink;
          } else {
            const loginLink = `https://${output.body.subDomainCode}.${output.body.tld}/accountlogin.html`;
            window.location.href = loginLink;
            toast.success("successfully User Signed up");
          }
        } else {
          setLoading(false);
          toast.error("Failed to Sign up");
        }
      }
    } catch (error) {
      toast.error("Failed to Sign up");

      console.log(error);
      setLoading(false);
    }
  };

  const previousBtnHandler = () => {
    if (isAccount) {
      dispatch(stepNavigate(3));
    } else {
      dispatch(stepNavigate(1));
    }
  };

  const getDetails = () => {
    setEmail(userData?.email);
  };

  useEffect(() => {
    getDetails();
  }, [userData]);

  const passwordMatch = () => {
    if (!(password === confirmPassword)) {
      setPasswordCheck(true);
    } else {
      setPasswordCheck(false);
    }
  };

  const strongPassword = () => {
    passwordMatch();
    if (!password.match(strongRegex)) {
      setStrongPswd(true);
    } else {
      setStrongPswd(false);
    }
  };

  // function for domain checking:aws s3 sync build s3:/
  const domainChecking = () => {
    try {
      let data = {
        domainName: userData.endPoint, //userData.endPoint, // `${domainURL}.${topLevelDomain}`,
        orgCode: subDomain,
      };
      axios
        .post(`https://cloud.${userData.endPoint}/isOrgCodeExists`, data, {
          headers: { "Content-Type": "application/json" },
        })
        // axios
        //   .post(`http://172.16.10.117:1337/isOrgCodeExists`, data, {
        //     headers: { "Content-Type": "application/json" },
        //   })
        .then((response) => {
          console.log("domain exist response", response);
          if (response?.status === 200 && response?.data?.available) {
            // true means -> the domain is allreday exist
            setIsDomain("Domain is already exists");
            setSubmitButtonState(true);
          } else {
            setSubmitButtonState(false);
            isOrgExistService(tld, subDomain).then((res) => {
              console.log("orgcode 111", res);
              if (res?.status === "success" && res?.data?.isOrgCodeExists) {
                console.log("yes111111111");
                setIsDomain("Domain is already exists");
                setSubmitButtonState(true);
              } else {
                setIsDomain(false);
                setSubmitButtonState(false);
              }
            });
          }
        })
        .catch((error) => {
          setSubmitButtonState(true);
          console.log(error);
        });
    } catch (error) {}
  };

  const orgNamecheckingHandler = async () => {
    const body = {
      orgName: orgName,
      tldId: tld,
    };
    try {
      const orgNameCheckResponse = await checkOrgNameService(body);
      setLoading(false);
      console.log("orgNameCheckResponse", orgNameCheckResponse);
      if (orgNameCheckResponse?.data?.isOrgNameExists) {
        setIsOrgNameExist(true);
        return true;
      } else {
        setIsOrgNameExist(false);
        return false;
      }
    } catch (error) {
      setIsOrgNameExist(true);
      setLoading(false);
      return true;
      console.log("error", error);
    }
  };

  // regular expressions :
  let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  var strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  const passwordText =
    "Password should have at least 1 capital letter, 1 small letter, 1 number, 1 special character with a maximum length of 8";
  const subDomainInfoText =
    "Account subdomain name should contain only letters, numbers, hypen or underscore";
  const formValidation = async () => {
    let isValid = true;
    // if (!email) {
    //   isValid = false;
    //   setErrorMsg(true);
    // }
    if (!password) {
      isValid = false;
      setErrorMsg(true);
    }
    if (!confirmPassword) {
      isValid = false;
      setErrorMsg(true);
    }
    if (!orgName && signupType === "account") {
      isValid = false;
      setErrorMsg(true);
    }
    if (!subDomain && signupType === "account") {
      isValid = false;
      setErrorMsg(true);
    }
    if (check === false) {
      isValid = false;
      setErrorMsg(true);
    }
    if (isDomain && signupType === "account") {
      isValid = false;
      setErrorMsg(true);
    }
    setLoading(true);
    const res = await orgNamecheckingHandler();
    if (isAccount&& res) {
      isValid = false;
      setErrorMsg(true);
    }
    return isValid;
  };
  // console.log(process.env.REACT_APP_ACCOUNT_SUB_DOMAIN);

  //for disabling the copy paste option
  const handleKeyDown = (event) => {
    if (event.ctrlKey) {
      event.preventDefault();
    }
  };
  return (
    <>
      <Spin
        spinning={loading}
        indicator={
          <LottieLoaders lottieConstant={lottieConstants.loaderBalls} />
        }
      >
        <div className="vwContainer scroll">
          {/* <div className="vwHeader">
          <div className="vwLogo">
            <img src={vwLogo} width="200px" alt="voltuswave logo" srcSet="" />
          </div>
        </div> */}
          <div className="vwContent">
            <div className="vwCreatePasswordHead">
              <strong>Create a Password</strong>
              <br />
              <small className="text">Account Password</small>
            </div>
            <div className="row inputField">
              <div className="col">
                <label>Email </label>
                <div>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    disabled
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <div>
                    {(errorMsg && !email && (
                      <span className="errorMsg">Email is required</span>
                    )) ||
                      (errorMsg && !email.match(regex) && (
                        <span className="errorMsg">
                          Please enter valid email
                        </span>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="row inputField">
              <div className="col">
                <label>
                  Password *{" "}
                  <Tooltip placement="rightBottom" title={passwordText}>
                    <img
                      src={Information}
                      className="informIcon"
                      height="13px"
                      srcSet=""
                    />
                  </Tooltip>
                </label>
                <div className="passwordContainer">
                  <input
                    type={showPswd ? "text" : "password"}
                    className="form-control"
                    name="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    onBlur={strongPassword}
                  />
                  <img
                    src={showPswd ? viewPswd : hidePswd}
                    srcSet=""
                    onClick={() => {
                      setShowPswd(!showPswd);
                    }}
                    height="20px"
                    className="eyeIcon"
                  />
                  <div>
                    {(errorMsg && !password && (
                      <span className="errorMsg">Password is required</span>
                    )) ||
                      (strongPswd && (
                        <span className="errorMsg">
                          Required strong password
                        </span>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="row inputField">
              <div className="col">
                <label>Confirm Password *</label>
                <div className="passwordContainer">
                  <input
                    type={showConfirmPswd ? "text" : "password"}
                    className="form-control"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    onBlur={passwordMatch}
                    onKeyDown={handleKeyDown}
                  />
                  <img
                    src={showConfirmPswd ? viewPswd : hidePswd}
                    srcSet=""
                    onClick={() => {
                      setShowConfirmPswd(!showConfirmPswd);
                    }}
                    height="20px"
                    className="eyeIcon"
                  />
                  <div>
                    {(errorMsg && !confirmPassword && (
                      <span className="errorMsg">
                        Confirm password is required
                      </span>
                    )) ||
                      (passwordCheck && (
                        <span className="errorMsg">
                          Confirm password is not matched
                        </span>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            {isAccount && (
              <div className="row inputField">
                <div className="col">
                  <label>Organization Name *</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      name="orgName"
                      value={orgName}
                      onChange={(e) => {
                        setOrgName(e.target.value);
                        setIsOrgNameExist(false);
                      }}
                      onBlur={() => {
                        orgNamecheckingHandler();
                      }}
                    />
                    <div>
                      {errorMsg && !orgName && (
                        <span className="errorMsg">
                          Organization name is required
                        </span>
                      )}
                      {isOrgNameExist && (
                        <span className="errorMsg">
                          Organization Name is Exist Already
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* {console.log("isusernow", isUser)} */}
            {isAccount && (
              <div className="row inputField">
                <div className="col">
                  <label>
                    Account Subdomain *{" "}
                    <Tooltip placement="rightBottom" title={subDomainInfoText}>
                      <img
                        src={Information}
                        className="informIcon"
                        height="13px"
                        srcSet=""
                      />
                    </Tooltip>
                  </label>
                  <div className="input-group mb-3 subDomainContainer">
                    <input
                      type="text"
                      className="form-control"
                      maxLength="10"
                      value={subDomain}
                      onChange={(e) => {
                        setSubDomain(e.target.value);
                        setIsDomain(false);
                      }}
                      onKeyDown={(evt) =>
                        [
                          "~",
                          "!",
                          "+",
                          "*",
                          ".",
                          "@",
                          "#",
                          "$",
                          "%",
                          "^",
                          "&",
                          "(",
                          ")",
                          " ",
                          ";",
                          "'",
                          '"',
                          "/",
                          "?",
                          "`",
                          "=",
                          "{",
                          "}",
                          "[",
                          "]",
                          "|",
                        ].includes(evt.key) && evt.preventDefault()
                      }
                      name="subDomain"
                      style={{ outline: "unset" }}
                      onBlur={domainChecking}
                    />
                    <span className="subDomain">.{userData.endPoint}</span>
                  </div>
                  <div>
                    {errorMsg && !subDomain && (
                      <span className="errorMsg">Subdomain is required</span>
                    )}
                    {isDomain && <span className="errorMsg">{isDomain}</span>}
                  </div>
                </div>
              </div>
            )}
            <div className="row inputField">
              <div className="col">
                <label>Recaptcha Validation *</label>
                <ReCAPTCHA
                  sitekey="6Ldb3l8UAAAAAO8xBJ1q_GLfDn2kkcQNBCqC02GU"
                  onChange={recaptchaHandler}
                  style={{ marginBottom: "20px" }}
                />
              </div>
            </div>
            <div style={{ marginLeft: "1.5rem" }}>
              <input
                type="checkbox"
                value="check"
                onChange={() => {
                  setCheck(!check);
                }}
                name=""
                id=""
              />
              <span>
                {" "}
                I agree to the{" "}
                <a
                  href="https://voltuswave.com/terms-and-conditions"
                  target="_blank"
                >
                  Terms & Conditions
                </a>{" "}
                and
                <a href="https://voltuswave.com/privacy-policy" target="_blank">
                  {"\t"}Privacy Policy
                </a>
              </span>
              {/* <div>
                {errorMsg && check === false && <span className='errorMsg'>please accept terms&conditions</span>}
                </div> */}
            </div>
            <div className="buttonContainerCreatePassword">
              <button className="previousBtn" onClick={previousBtnHandler}>
                Previous
              </button>
              <button
                className="submitBtn"
                onClick={submitHandler}
                style={{
                  backgroundColor: disableSubmitButton ? "#7bb0fa" : "  ",
                  cursor: disableSubmitButton ? "not-allowed" : "pointer",
                }}
              >
                {/* {loading ? loader(loading) : "Submit"} */}
                Submit
              </button>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default CreatePassword;

// let data = {
// 	leadId: userData?.leadId,
//    leadOrganisation: orgName,
//    leadPassword: confirmPassword,
//    leadSubdomainCode: subDomain,
//    captcha: captcha,
//    journeyIndex: 9,
//  };

//  let ontherData = {
// 	 name:userData?.firstName + " " + userData?.lastName,
// 	 firstName:userData?.firstName ,
// 	 lastName:userData?.lastName,
// 	 email:userData?.email,
// 	 password:confirmPassword,
//    //   recaptcha: captcha,
// 	 orgName:orgName,
// 	 orgCode:subDomain,
// 	 domainName: "localhost",
// 	 orgJoinId: null,
// 	 source: "Web",
// 	 deviceId: "localhost",
// 	 mobileNumber: "",
// 	 countryCode: "",
// 	 signupOption: "email",
// 	 subDomainCode: "cloud",
// 	 domainUrl: "localhost",
// 	 RegNo: 20001,
// 	 authenticationType: "Default",
// 	 solutionId: null,
// 	 profile:  null,
// 	 personProfilePicture:  null,
// 	 socialConnectionName:  null
// 	 }

// Promise.all([
//    await createPasswordService(data),
//    axios.post('http://172.16.114.147:1337/onboardingSignup',ontherData)
// ]).then((response)=>{
//    console.log(response);

// }).catch((error)=>{
//    console.log(error);
// })

// if (
//   vlsResponse?.status === "success" &&
//   vlsResponse?.data?.addedIdentityDetails
// ) {
//   let otherData;
//   if (isAccount) {
//     otherData = {
//       RegNo: 20001,
//       authenticationType: "Default",
//       countryCode: "",
//       deviceId: userData.endPoint, //userData.endPoint || "voltusone.com",
//       domainName: userData.endPoint, //userData.endPoint || "voltusone.com",
//       domainUrl: userData.endPoint, //userData.endPoint || "voltusone.com",
//       // deviceId: "localhost",
//       // domainName: "localhost",
//       // domainUrl: "localhost",
//       email: userData?.email,
//       recaptcha: null,
//       firstName: userData?.firstName,
//       lastName: userData?.lastName,
//       mobileNumber: userData.mobile,
//       name: userData?.firstName + " " + userData?.lastName,
//       orgCode: subDomain,
//       orgName: orgName,
//       orgJoinId: null,
//       password: confirmPassword,
//       personProfilePicture: null,
//       profile: null,
//       signupOption: "email",
//       socialConnectionName: null,
//       source: "Web",
//       subDomainCode: subdomain ? "qa-onboarding" : "cloud",
//       solutionId: null,
//     };
//   } else {
//     otherData = {
//       RegNo: 20001,
//       countryCode: "",
//       domainName: userData.endPoint,
//       email: userData?.email,
//       firstName: userData?.firstName,
//       lastName: userData?.lastName,
//       mobileNumber: userData.mobile,
//       isAdmin: false,
//       isNewUser: true,
//       orgCode: userData.subdomainCode,
//       orgName: userData.orgName,
//       orgId: userData.vwpOrgId,
//       password: confirmPassword,
//     };

//     ///subdomian from reudux for invite user
//     setSubDomain(userData.subdomainCode);
//   }

//   // axios
//   //   .post(
//   //     `http://172.16.10.117:1337/onboardingSignup${
//   //       isAccount
//   //         ? location.search
//   //         : `?signupType=${signupType}&tldId=${tld}${
//   //             entryPointId === undefined
//   //               ? ""
//   //               : "" //`&entryPointId=${entryPointId}`
//   //           }`
//   //     }`,
//   //     otherData
//   //   )
//   // console.log("invite type" , entryPointId);
//   axios
//     .post(
//       `https://cloud.${userData.endPoint}/onboardingSignup${
//         isAccount
//           ? location.search
//           : `?signupType=${signupType}&tldId=${tld}${
//               entryPointId === undefined || entryPointId === null
//                 ? ""
//                 : "" //`&entryPointId=${entryPointId}`
//             }`
//       }`,
//       otherData
//     )
//     .then((response) => {
//       console.log("vwp response:", response);
//       if (
//         response?.status === 200 &&
//         response?.data?.check &&
//         response?.data?.success
//       ) {
//         const vwpOrgDetails = {
//           vlsOrgId: vlsResponse.data.dbResponse.vlsOrgId,
//           vwpClusterId: response?.data.data.clusterId,
//           vwpOrgId: response?.data.data.orgId,
//           vwpUserId: response?.data.data.personId,
//           vwpTldId: response?.data.data.tldId,
//           vlsUserId: vlsResponse.data.dbResponse.leadId,
//           vwpOrgPersonId: response?.data.data?.orgPersonVwid,
//           vwpWorkspaceId: response?.data.data?.workspaceId,
//           vwpWorkspacePersonId:
//             response?.data.data.workspacePersonVwpId,
//           orgSchemeId: response?.data.data?.orgSchemeId,
//           entryPointId: entryPointId,
//           emailDetails: {
//             name: userData?.firstName + " " + userData?.lastName,
//             email: userData?.email,
//             subDomainCode:
//               subDomain === "" ? userData.subdomainCode : subDomain,
//             tld: userData.endPoint,
//             redirectUrl: response?.data?.data?.emailRedirectionLink,
//             isAccount: isAccount,
//           },
//           orgLogo: response?.data.data.orgLogo,
//           orgName: response?.data.data.orgName,
//         };

//         if (isAccount) {
//           userIdExist({
//             variables: {
//               user_id: vwpOrgDetails.vwpOrgPersonId,
//               origin_id: `${vwpOrgDetails.vwpTldId}_${vwpOrgDetails.vwpOrgId}`,
//             },
//             onCompleted: (response) => {
//               // console.log({response})

//               if (!response.userIDExists) {
//                 addUserForChatEnable({
//                   variables: {
//                     user_name: vwpOrgDetails.emailDetails?.name,
//                     user_id: vwpOrgDetails.vwpOrgPersonId,
//                     org_id: vwpOrgDetails.vwpOrgId,
//                     tld_id: vwpOrgDetails.vwpTldId,
//                   },
//                   onCompleted: (enableChatResponse) => {
//                     // console.log({enableChatResponse})

//                     if (
//                       enableChatResponse.addUser.user_id ===
//                       vwpOrgDetails.vwpOrgPersonId
//                     ) {
//                       addOrgDetails({
//                         variables: {
//                           org_id: vwpOrgDetails.vwpOrgId,
//                           tld_id: vwpOrgDetails.vwpTldId,
//                           org_name: vwpOrgDetails.orgName,
//                           short_description: "",
//                           long_description: "",
//                           image_id: vwpOrgDetails.orgLogo,
//                         },
//                         onCompleted: (addOrgDetailsRes) => {
//                           // console.log({addOrgDetailsRes});
//                         },
//                       });
//                     }
//                   },
//                 });
//               }
//             },
//           });
//         } else {
//           userIdExist({
//             variables: {
//               user_id: vwpOrgDetails.vwpOrgPersonId,
//               origin_id: `${tld}_${userData.vwpOrgId}`,
//             },
//             onCompleted: (response) => {
//               // console.log({response})

//               if (!response.userIDExists) {
//                 addUserForChatEnable({
//                   variables: {
//                     user_name: `${userData.firstName} ${userData.lastName} `,
//                     user_id: vwpOrgDetails.vwpOrgPersonId,
//                     org_id: userData.vwpOrgId,
//                     tld_id: tld,
//                   },
//                   onCompleted: (enableChatResponse) => {
//                     // console.log({enableChatResponse})

//                     if (
//                       enableChatResponse.addUser.user_id ===
//                       vwpOrgDetails.vwpOrgPersonId
//                     ) {
//                       addOrgDetails({
//                         variables: {
//                           org_id: userData.vwpOrgId,
//                           tld_id: tld,
//                           org_name: userData.orgName,
//                           short_description: "",
//                           long_description: "",
//                           image_id: vwpOrgDetails.orgLogo,
//                         },
//                         onCompleted: (addOrgDetailsRes) => {
//                           // console.log({addOrgDetailsRes});
//                         },
//                       });
//                     }
//                   },
//                 });
//               }
//             },
//           });
//         }

//         //next vls call
//         sendOrgDetailsService(vwpOrgDetails)
//           .then((res) => {
//             console.log("lastapi", res.data);
//             if (res.data.isVwpOrgDetailsAdded === true) {
//               console.log(
//                 "redirect url:",
//                 response?.data?.data?.emailRedirectionLink
//               );

//               // window.location.href = `${response?.data?.data?.emailRedirectionLink}`;
//               // window.location.href = `http://${
//               //   subDomain === "" ? userData.subdomainCode : subDomain
//               // }.localhost/accountlogin.html`;
//               if (entryPointId) {
//                 // alert(entryPointId, "1");
//                 const body = {
//                   vwpOrgId: vwpOrgDetails.vwpOrgId,
//                   orgEmail: vwpOrgDetails.emailDetails.email,
//                   vlsOrgId: vwpOrgDetails.vlsOrgId,
//                   vlsUserId: vwpOrgDetails.vlsUserId,
//                   entryPointId: entryPointId,
//                 };
//                 if (isAccount) {
//                   createSubcriptionInStripeService(body)
//                     .then((result) => {
//                       console.log(result, "result from subscription");
//                       if (result.data.isSubscribed) {
//                         window.location.href = `https://${
//                           subDomain === ""
//                             ? userData.subdomainCode
//                             : subDomain
//                         }.${userData.endPoint}/accountlogin.html`;
//                         setLoading(false);
//                       } else {
//                         toast.error("subscription failed");
//                         setLoading(false);
//                       }
//                     })
//                     .catch((err) => {
//                       toast.error("subscription failed");
//                       setLoading(false);
//                       console.log(err);
//                     });
//                 } else {
//                   // window.location.href = `https://${
//                   //   subDomain === ""
//                   //     ? userData.subdomainCode
//                   //     : subDomain
//                   // }.${userData.endPoint}/accountlogin.html`;
//                   toast.success("Signed up successfully!");
//                   setLoading(false);
//                 }
//               } else {
//                 alert(entryPointId, "2");
//                 // window.location.href = `${response?.data?.data?.emailRedirectionLink}`

//                 // window.location.href = `https://${
//                 //   subDomain === "" ? userData.subdomainCode : subDomain
//                 // }.${userData.endPoint}/accountlogin.html`;
//                 toast.success("Signed up successfully!");
//                 setLoading(false);
//               }
//             } else {
//               setLoading(false);
//             }
//           })
//           .catch((err) => {
//             console.log(err);
//             setLoading(false);
//           });
//       } else {
//         setLoading(false);
//         toast.error(response.data.data.message);
//       }
//     })
//     .catch((error) => {
//       console.log(error);
//       setLoading(false);
//     });
//   // toast.success('data submitted succesfully')
// } else {
//   setLoading(false);
//   toast.error(vlsResponse.response.data.data.message);
// }

// const emailContent = {
//   loginUrl: loginLink,
//   orgName: output.body.orgDetails.vlsOrgDetails._org_name,
//   name: `${output.body.orgDetails.vlsOrgDetails._first_name} ${output.body.orgDetails.vlsOrgDetails._last_name} `,
//   email: output.body.orgDetails.vlsOrgDetails._email
// }

// const userSignupHtml = render(<UserSignup data={emailContent} />, { pretty: true });
// console.log({ userSignupHtml });

// const userSignupConfirmationForAdminHtml = render(<UserSignupForAdmin data={emailContent} />, { pretty: true })
// console.log({ userSignupConfirmationForAdminHtml });

// const emailData = [];

// const UserEmailData = {
//   email: userData.email,
//   template: userSignupHtml,
//   subject: "Voltuswave Signup Confirmation"
// }

// const AdminEmailData = {
//   email: output.body.dbResponse.orgPersonEmailId,
//   template: userSignupConfirmationForAdminHtml,
//   subject: "Voltuswave User Signup Confirmation"
// }

// emailData.push(UserEmailData, AdminEmailData)

// const emailResponse = await sendEmailsService(emailData );
// console.log({ emailResponse });

// import { render } from "@react-email/components";
// import AccountSignup from "../../emailTemplates/AccountSignup";
// import UserSignup from "../../emailTemplates/UserSignup";
// import UserSignupForAdmin from "../../emailTemplates/UserSignupFroAdmin";
// import { sendEmailsService } from "../../services/SendEmailsServices";
// import { commonSqsService } from "../../../../backend/src/handlers/CommonFiles/commonSqsService";

// const emailContent = {
//   loginUrl: loginLink,
//   orgName: output.body.subDomainCode,
//   firstName: ` ${userData.firstName}   ${userData.lastName}`,
//   email: userData.email
// }

// const html = render(<AccountSignup data={emailContent} />, { pretty: true });
// console.log({ html });

// const emailData = {
//   email: userData.email,
//   template: html,
//   subject: "Voltuswave Account Signup Confirmation"
// }

// const emailResponse = await sendEmailsService([emailData]);
// console.log({ emailResponse });

// useEffect(() => {
//   userIdExist({variables : {user_id:"a5c07daf-feeb-4342-80c0-044cfed19280" , origin_id:"df8b1e0b-f794-41a5-84f1-7bc684222158_cfaaf582-41d0-4837-81fa-1326613bd9b2" },onCompleted: (response)=>{
//     console.log({response})
//   }})
// }, [])
