import axios from "axios";
import instanceAll from "./commonAxios";
import { buildConstants } from "../utilies/buildConstants";



export const identityDetailsService = async (data) => {
	try {
		const response = await instanceAll.post("/insertIdentityDetails", data);
		// console.log("response:", response);
		if (response?.status === 200) {
			return {
				status: "success",
				data: response?.data
			}
		} else {
			return {
				status: "failed",
				data: response?.data
			}
		}
	} catch (error) {
		console.log(error);
	}
};

export const teamStrengthService = async (data) => {
	try {
		const response = await instanceAll.post("/insertTeamSize", data);
		// console.log("response:", response);
		if (response?.status === 200) {
			return {
				status: "success",
				data: response?.data
			}
		} else {
			return {
				status: "failed",
				data: response?.data
			}
		}
	} catch (error) {
		console.log(error);
	}
};

export const createPasswordService = async (data) => {
	let response;
	try {
		response = await axios.post(buildConstants.stepFunctionServer, data);
		console.log("response:", response);
		const output=JSON.parse(response?.data?.output);
		console.log("output",output);
		if (output.statusCode === 200) {
			return {
				status: "success",
				data: response?.data
			}
		} else {
			return {
				status: "failed",
				data: response?.data
			}
		}
	} catch (error) {
		console.log(error);
		return {
			status: "failed",
			data: response?.data
		}
	}
};
