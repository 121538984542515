import "./SocialSignUpButton.scss";
import { useEffect, useState } from "react";
import { getSocialSignupDetailsService } from "../../../services/orgJoinServices";
import { Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { buildConstants } from "../../../utilies/buildConstants";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import {
  existEmailCheckingService,
  existEmailCheckingServiceInVwp,
} from "../../../services/existEmailAndMobileService";
import {
  email,
  firstName,
  lastName,
  leadId,
  stepNavigate,
} from "../../../features/userData/userDataSlice";
import { EmailSignupService } from "../../../services/signupApiService";
import { getGeoApiLocation } from "../../../utilies/Utilies";

const SocialSignUpButton = (props) => {
  const { setIsEmailExist, setshowSignInUrl, nextStep, setLoading } = props;
  const [socialAccounts, setSocialAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const userData = useSelector((state) => state.userData);
  const [countryCode, setCountryCode] = useState();
  const dispatch = useDispatch();
  let payload;
  const queryObject = queryString.parse(location.search);
  const keysOfQueryParams = Object.keys(queryObject);
  const signupSchemeID = queryObject.signupSchemeID;

  const { schemaDetails } = useSelector((state) => state.userData);
  console.log("schemaDetails", schemaDetails);
  console.log("location++",window.location)

  const {host}=window.location
  const getKeysHandler = (acountName) => {
    const keys = schemaDetails.socialAccountKeys.filter(
      (each) => each.socialAccountName == acountName
    )[0];
    return keys;
  };

  const emailChecking = async (userEmail) => {
    setLoading(true);
    return new Promise((resolve) => {
      let status = true;
      try {
        existEmailCheckingServiceInVwp({
          leadEmail: userEmail,
          endPoint: userData.endPoint,
        })
          .then((response) => {
            console.log("vwpemailexist", response);
            if (response.data.available === true) {
              existEmailCheckingService({ leadEmail: userEmail })
                .then((response) => {
                  console.log(
                    "email exist response",
                    response.data.isEmailExisted,
                    response.status
                  );
                  if (response.status === "success") {
                    setIsEmailExist(true);
                    setshowSignInUrl(true);
                    status = false;
                  } else {
                    setIsEmailExist(false);
                    status = true;
                    console.log("else");
                  }
                  resolve(status);
                })
                .catch((error) => {
                  console.log("catch");
                  console.log(error);
                  setIsEmailExist(false);
                  status = false;
                  resolve(status);
                });
            } else {
              setIsEmailExist(true);
              setshowSignInUrl(true);
              status = true;
              resolve(status);
            }
          })
          .catch((error) => {
            setIsEmailExist(false);
            status = false;
            console.log("else");
            resolve(status);
          });
      } catch (error) {
        setIsEmailExist(false);
        status = false;
        console.log("else");
        resolve(status);
      }
    });
  };

  const Google = () => {
    const keys = getKeysHandler("Google");
    payload = {
      host:host,
      signupSchemeID: signupSchemeID,
      clientId: keys.clientId,
      // "1075765774325-q7ckdt4oknl9uqub9ltd3cr758m7v5jm.apps.googleusercontent.com",
      apiKey: keys.apiKey,
      // "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",
    };

    // Convert payload to a query string
    const queryString = Object.keys(payload)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}`
      )
      .join("&");

    console.log("queryString", queryString);

    window.open(
      `${buildConstants.backendServer}auth/google?${queryString}`,
      "_self"
    );
  };
  const Microsoft = () => {
    const keys = getKeysHandler("Microsoft");
    payload = {
      host:host,
      signupSchemeID: signupSchemeID,
      clientId: keys.clientId,
      // "9a53f404-52b6-478b-8bf0-cb6abeb654ee",
      apiKey: keys.apiKey,
      //"7t48Q~jk7bhGxRlzwJxayWfp9DDMyloHsAfhTbNS",
    };

    // Convert payload to a query string
    const queryString = Object.keys(payload)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}`
      )
      .join("&");

    console.log("queryString", queryString);

    window.open(
      `${buildConstants.backendServer}auth/microsoft?${queryString}`,
      "_self"
    );
  };
  const Facebook = () => {
    const keys = getKeysHandler("Facebook");
    payload = {
      host:host,
      signupSchemeID: signupSchemeID,

      clientId: keys.clientId,
      //"1076060566849061",
      apiKey: keys.apiKey,
      //"7b8bbc3ba2849a74f6cc30b6db06fdc1",
    };

    // Convert payload to a query string
    const queryString = Object.keys(payload)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}`
      )
      .join("&");

    console.log("queryString", queryString);
    // alert("ok")

    window.open(
      `${buildConstants.backendServer}auth/facebook?${queryString}`,
      "_self"
    );
  };

  const Twitter = () => {
    const keys = getKeysHandler("Twitter");
    payload = {
      host:host,
      signupSchemeID: signupSchemeID,

      clientId: keys.clientId,
      // "ki0pZa68fVAFEozgGkfk0irNT",

      apiKey: keys.apiKey,
      // "jQce8KMKGZpE4uV9kjf3O4KBHp2KQ9322uKyD7JzAYJY1SCECf",
    };

    // Convert payload to a query string
    const queryString = Object.keys(payload)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}`
      )
      .join("&");

    console.log("queryString", queryString);
    // alert("ok")

    window.open(
      `${buildConstants.backendServer}auth/twitter?${queryString}`,
      "_self"
    );
  };
  const Linkedin = () => {
    const keys = getKeysHandler("linkedin");
    payload = {
      host:host,
      signupSchemeID: signupSchemeID,
      clientId: keys.clientId,
      // "86vq5g31f3hwjm",
      apiKey: keys.apiKey,
      //  "6T6BwnRLqHcgfGT7",
    };

    // Convert payload to a query string
    const queryString = Object.keys(payload)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}`
      )
      .join("&");

    console.log("queryString", queryString);
    // alert("ok")

    window.open(
      `${buildConstants.backendServer}auth/linkedin?${queryString}`,
      "_self"
    );
  };

  const getSocialSignupDetails = async () => {
    setIsLoading(true);
    try {
      const getSocialSignupDetailsResponse =
        await getSocialSignupDetailsService();
      setIsLoading(false);
      setSocialAccounts(getSocialSignupDetailsResponse.data.dbResponse);
      console.log(
        "getSocialSignupDetailsResponse",
        getSocialSignupDetailsResponse
      );
      await logUserData();
    } catch (err) {
      setIsLoading(false);
      console.log("err", err);
    }
  };

  useEffect(() => {
    getSocialSignupDetails();
    getGeoApiLocation(setCountryCode, "email",userData.ipnfoToken);
    return () => {};
  }, []);

  const submitHandler = async (userfirstName, userlastName, useremail) => {
    try {
      if (true) {
        //!isEmailExist
        // setLoading(true);
        let data = {
          firstName: userfirstName,
          lastName: userlastName,
          emailId: useremail,
          campaignId: 68576,
          sourceId: 1,
          journeyIndex: 1,
          countryCode: countryCode,
          isSocialLogin: true,
          userData:userData
        };
        // setIsEmailVerified(true);
        const response = await EmailSignupService(data);
        console.log("email signup response", response);
        if (response?.status === "success") {
          // setLoading(false);
          dispatch(firstName(response?.data?.data?.firstName));
          dispatch(lastName(response?.data?.data?.lastName));
          dispatch(leadId(response?.data?.data?.leadId));
          dispatch(email(response?.data?.data?.leadEmail));
          dispatch(stepNavigate(nextStep));
          // navigate("/emailOtpVerify");
        } else {
          // setLoading(false);
          // toast.error("something went wrong");
        }
      } else {
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const logUserData = async () => {
    try {
      if (queryObject["userData"]) {
        console.log(
          "keysOfQueryParams sconds param",
          keysOfQueryParams,
          queryObject["userData"]
        );
        const userDetailsString = queryObject["userData"] || "sdf";
        const jsonStringw = atob(userDetailsString);
        const decodedObject = JSON.parse(jsonStringw);
        const { firstName, lastName, email } = decodedObject;
        console.log("decodedObject", decodedObject);

        const emailCheckStatus = await emailChecking(decodedObject.email);

        console.log("emailCheckStatus", emailCheckStatus);
        if (!emailCheckStatus) {
          await submitHandler(firstName, lastName, email);
          // alert("if")
          setLoading(false);
        } else {
          setLoading(false);
          console.log("email is exist already");
          // alert("else")
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div className="passportjsSocialsingupiv">
      {/* <div className="loginButton google" onClick={() => google()}>
        <img src={Google} alt="" className="icon" />
        Google
      </div>
      <div className="loginButton facebook" onClick={facebook}>
        <img src={Facebook} alt="" className="icon" />
        Facebook
      </div> */}
      <Skeleton loading={isLoading} active>
        {socialAccounts.length &&
          socialAccounts.map((account, id) => {
            const socialAccount =
              account._social_connection_name == "Google"
                ? Google
                : account._social_connection_name == "Microsoft"
                ? Microsoft
                : account._social_connection_name == "Facebook"
                ? Facebook
                : account._social_connection_name == "Twitter"
                ? Twitter
                : account._social_connection_name == "Linkedin"
                ? Linkedin
                : "";

            if (
              schemaDetails?.socialAccount?.includes(
                account._social_connection_name
              )
            ) {
              return (
                <div
                  key={id}
                  style={{ display: "flex" }}
                  onClick={socialAccount}
                >
                  <div className="socialAccountitem">
                    <div className="iconContainer">
                      <img src={account._image_url} height="25" alt="" />
                    </div>
                    <span>{account._social_connection_name}</span>
                  </div>
                </div>
              );
            }
          })}
      </Skeleton>
    </div>
  );
};

export default SocialSignUpButton;
