import instanceAll from "./commonAxios";

export const resendEmailOtpService = async (data) => {
	try {
		const response = await instanceAll.post("/resendEmailOtp", data);
		// console.log("response:", response);
		if(response?.status === 200){
			return {
			 status :"success",
			 data:response?.data
			}
		 }else{
			return {
			 status :"failed",
			 data :response?.data
			}
		 }
	} catch (error) {
		console.log(error);
	}
};

export const resendMobileOtpService = async (data) => {
	try {
		const response = await instanceAll.post("/resendMobileOtp", data);
		// console.log("response:", response);
		if(response?.status === 200){
			return {
			 status :"success",
			 data:response?.data
			}
		 }else{
			return {
			 status :"failed",
			 data :response?.data
			}
		 }
	} catch (error) {
		console.log(error);
	}
};
