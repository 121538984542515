import React, { useEffect, useState } from "react";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
// import vwLogo from "../../assets/images/vw-logo.png";
// import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { EmailSignupService } from "../../services/signupApiService";
import {
  email,
  firstName,
  lastName,
  leadId,
  isEmailVerified,
  isSocialLogin,
  endPoint,
  tldId,
  stepNavigate,
} from "../../features/userData/userDataSlice";
import { toast } from "react-toastify";
import {
  LottieLoaders,
  getGeoApiLocation,
  loader,
  lottieConstants,
  onkeyPressHandler,
} from "../../utilies/Utilies";
import axios from "axios";
import GoogleSignup from "./GoogleSignup";
import MicrosoftSignup from "./MicrosoftSignup";
import EmailOtpVerify from "./EmailOtpVerify";
import {
  existEmailCheckingService,
  existEmailCheckingServiceInVwp,
} from "../../services/existEmailAndMobileService";
import { getEndPointByTldIdService } from "../../services/getEndPointByTldIdService";
import { Button, Space, Spin } from "antd";
import { useLazyQuery, gql } from "@apollo/client";
import SocialSignUpButton from "./SocialSignUpButton/SocialSignUpButton";
import queryString from "query-string";
import { emailCheckInOrgService } from "../../services/emailCheckInOrgService";
// import SignupEmailOtp from "../../emailTemplates/SignupEmailOtp";
// import { render } from "@react-email/components";
// import { sendEmailsService } from "../../services/SendEmailsServices";

const SignUp = ({ isOptional = "", nextStep = "" }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  const {signupTypeInUrl,schemaDetails,orgJoinOrgDetails}=useSelector((state)=>state.userData)
  // console.log("signupTypeInUrl",signupTypeInUrl)
  // console.log("userData:",userData);

  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState();
  const [type, setType] = useState("email");
  const [isEmailExist, setIsEmailExist] = useState(true);
  const [activeId, setActiveId] = useState("submitBtn");
  const [isTldIdExist, setisTldIdExist] = useState(false);
  const [showSignInUrl, setshowSignInUrl] = useState(false);
  const [emailCheckStatus, setEmailCheckStatus] = useState("");
  // console.log("useLocation", location.search.split("&")[1].split("=")[1]);
 
  // Parse the JSON string to get the original object

  const submitHandler = async () => {
    try {
      if (formValidation() && !isEmailExist) {
        setLoading(true);
        let data = {
          firstName: userFirstName,
          lastName: userLastName,
          emailId: userEmail,
          campaignId: 68576,
          sourceId: 1,
          journeyIndex: 1,
          countryCode: countryCode,
          isSocialLogin: false,
          platformId:userData.tldId,
          eventId:1,
          userData:userData
        };
        const response = await EmailSignupService(data);
        console.log("email signup response", response);
        if (
          response?.status === "success" &&
          response?.data?.emailOtpGenerated &&
          response?.data?.data?.isEmailOtpSend
        ) {
          setLoading(false);
          dispatch(firstName(response?.data?.data?.firstName));
          dispatch(lastName(response?.data?.data?.lastName));
          dispatch(leadId(response?.data?.data?.leadId));
          dispatch(email(response?.data?.data?.leadEmail));
          dispatch(isEmailVerified(true));

          // dispatch(stepNavigate(2))
          // navigate("/emailOtpVerify");
        } else {
          setLoading(false);
          toast.error("something went wrong");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const user = "user";
  console.log("link for end point", window.location.host);

  // const getTldId = async () => {
  //   const data = {
  //     // endPoint: window.location.host.split("onboarding.")[1],
  //     endPoint:"voltuswave.com"
  //   };
  //   try {
  //     console.log(
  //       "data in non ",
  //       data,
  //       window.location.host.split("onboarding.")
  //     );
  //     const getTldIdResponse = await getEndPointByTldIdService(data);
  //     console.log("getTldIdResponse", getTldIdResponse.data);
  //     if (getTldIdResponse.data.isTldIdExist) {
  //       dispatch(endPoint(getTldIdResponse.data.dbResponse.vwEndpoint));
  //       dispatch(tldId(getTldIdResponse.data.dbResponse.tldId));
  //       setisTldIdExist(true);
  //       console.log(
  //         getTldIdResponse.data.dbResponse.vwEndpoint,
  //         "endpointbasedontldId"
  //       );
  //     } else {
  //       setisTldIdExist("TldId is not Valid");
  //     }
  //   } catch (err) {
  //     console.log("getTldIdErr", err);
  //     setisTldIdExist("TldId is not Valid");
  //   }
  // };
  // useEffect(() => {
  //   // getTldId();
  //   return () => {};
  // }, []);


  const emailChecking = async () => {
    setEmailCheckStatus("validating email...");
    if (userEmail.match(regex)) {

      if (userData.tldId) {
        setisTldIdExist(true)
     } 

      try {
        const response = await existEmailCheckingServiceInVwp({
          leadEmail: userEmail,
          endPoint: userData.endPoint,
        });

        console.log("vwpemailexist", response);
        if (response.data.available === true) {
          existEmailCheckingService({ leadEmail: userEmail })
            .then((response) => {
              console.log(
                "email exist response",
                response.data.isEmailExisted,
                response.status
              );
              if (
                response?.status === "success" &&
                response?.data?.isEmailExisted === true
              ) {
                setEmailCheckStatus("");
                setIsEmailExist(true);
                setshowSignInUrl(true);
              } else {
                setEmailCheckStatus("");
                setIsEmailExist(false);
                console.log("else");
              }
            })
            .catch((error) => {
              setEmailCheckStatus("");
              console.log("catch");
              console.log(error);
              setIsEmailExist(false);
            });
        } else {
          setIsEmailExist(true);
          setshowSignInUrl(true);
          setEmailCheckStatus("");
        }
      } catch (error) {
        setEmailCheckStatus("");
        setIsEmailExist(false);
        console.log("else");
      }
    }
  };


  console.log("isEmailExist",isEmailExist)
  // console.log("loader:",loading);
  const getDetails = () => {
    if (userData) {
      setUserFirstName(userData?.firstName);
      setUserLastName(userData?.lastName);
      setUserEmail(userData?.email);
    }
  };


  const emailCheckInOrg=async()=>{
    setEmailCheckStatus("validating email...");
    const payload={emails:[userEmail],orgId:orgJoinOrgDetails.vlsOrgId}
      try{
          const dbResponse=await emailCheckInOrgService(payload)
          const statusResponse=dbResponse?.data?.users[0]?.isEmailExist
          console.log("emailCheckInOrgService",dbResponse)
          if(!statusResponse){
            setEmailCheckStatus("");
            setIsEmailExist(false);
          }else{
            setEmailCheckStatus("Email is already exist in the Account");
            setIsEmailExist(true);
          }
      }catch(err){
          console.log("err",err)
      }
  }
  // console.log('country code:',countryCode);
  useEffect(() => {
    let isMounted = true;
    getDetails();

    getGeoApiLocation(setCountryCode, type,userData.ipnfoToken);
  }, [userData]);

  useEffect(() => {
    if (userEmail.match(regex)) {
      const delayDebounceFn = setTimeout(() => {
        if(signupTypeInUrl=="orgJoinSchemeID"){
          emailCheckInOrg()
        }else{
          emailChecking();

        }
      }, 1000);

      return () => {
        clearTimeout(delayDebounceFn);
      };
    }
  }, [userEmail]);

  let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  let regName = /^[a-zA-Z0-9 ]+$/;

  const formValidation = () => {
    let isValid = true;

    if (!userFirstName.match(regName)) {
      isValid = false;
      setErrorMsg(true);
    }
    if (!userEmail.match(regex)) {
      isValid = false;
      setErrorMsg(true);
    }
    return isValid;
  };

  const buttonStatusFunction = () => {
    if (isEmailExist || !isTldIdExist) {
      return true;
    } else {
      return false;
    }
  };

  const skipStep = () => {
    dispatch(stepNavigate(nextStep));
  };

  return (
    <div>
      <Spin
        spinning={loading}
        indicator={
          <LottieLoaders lottieConstant={lottieConstants.loaderBalls} />
        }
      >
      <div className="vwContainer">
        {/* <div className="vwHeader">
          <div className="vwLogo">
            <img src={vwLogo} width="200px" alt="voltuswave logo" srcSet="" />
          </div>
        </div> */}
        {!userData.isEmailVerified && (
          <div className="vwContent">
            <div className="vwSignUpHeadText">
              <strong>Sign Up</strong>
              <br />
              <small className="text">
                Get started today by entering just a few details.
              </small>
            </div>
            <div className="row inputField" style={{ marginTop: 30 }}>
              <div className="col">
                <label>First Name *</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    value={userFirstName}
                    onChange={(e) => {
                      setUserFirstName(e.target.value);
                    }}
                  />
                  <div>
                    {(errorMsg && !userFirstName && (
                      <span className="errorMsg">First name is required</span>
                    )) ||
                      (errorMsg && !userFirstName.match(regName) && (
                        <span className="errorMsg">valid name is required</span>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="row inputField">
              <div className="col">
                <label>Last Name</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    value={userLastName}
                    onChange={(e) => {
                      setUserLastName(e.target.value);
                    }}
                  />
                  <div>
                    {userLastName?.length > 0 &&
                      errorMsg &&
                      !userLastName?.match(regName) && (
                        <span className="errorMsg">valid name is required</span>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row inputField">
              <div className="col">
                <label>Email *</label>
                <div>
                  <input
                    type="email"
                    className="form-control"
                    value={userEmail}
                    onChange={(e) => {
                      setUserEmail(e.target.value);
                      setIsEmailExist(true);
                      setshowSignInUrl(false);
                    }}
                    // onBlur={emailChecking}
                    onKeyPress={(event) => {
                      onkeyPressHandler(event, activeId);
                    }}
                  />
                  <div className="errorMsg">{emailCheckStatus}</div>
                  <div>
                    {(errorMsg && !userEmail && (
                      <span className="errorMsg">Email is required</span>
                    )) ||
                      (errorMsg && !userEmail.match(regex) && (
                        <span className="errorMsg">
                          Please enter valid email
                        </span>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            {showSignInUrl ? (
              <span className="linkMsg">
                Welcome you are already part of Voltuswave. Please{" "}
                <a
                  href={`https://cloud${process.env.REACT_APP_ACCOUNT_SUB_DOMAIN}/accountlogin.html`}
                >
                  Login
                </a>
              </span>
            ) : null}
            {console.log("bothTest", isEmailExist && isTldIdExist)}
            <div className="buttonContainer">
              {isOptional && (
                <button
                  id="previousBtn"
                  className="previousBtn"
                  onKeyUp={skipStep}
                  onClick={skipStep}
                >
                  Skip
                </button>
              )}
              <button
                id="submitBtn"
                className="submitBtn"
                onKeyUp={submitHandler}
                onClick={submitHandler}
                disabled={isEmailExist}
                style={{
                  backgroundColor:
                    isEmailExist ? "#7bb0fa" : "  ",
                  cursor:
                    isEmailExist  ? "not-allowed" : "pointer",
                }}
              >
                Next
                {/* {loading ? loader(loading) : "Next"} */}
              </button>
              {isTldIdExist && <p className="errorMsg">{isTldIdExist}</p>}
              {/* <Button
                type="primary"
                onClick={submitHandler}
                disabled={buttonStatusFunction()}
                className="emailSubmitButton"
              >
                {loading ? loader(loading) : "Next"}
              </Button> */}
            </div>

           {(signupTypeInUrl=="signupSchemeID"||signupTypeInUrl=="orgJoinSchemeID")&&schemaDetails.socialAccount&& <div className="OrElement">
              <h3>
                <span>OR</span>
              </h3>
            </div>}

           {signupTypeInUrl==="account"&& <div className="scoialLoginContainer">
              <GoogleSignup
                setIsEmailExist={setIsEmailExist}
                setshowSignInUrl={setshowSignInUrl}
              />
              <MicrosoftSignup
                setIsEmailExist={setIsEmailExist}
                setshowSignInUrl={setshowSignInUrl}
              />
            </div>}
           { signupTypeInUrl!=="account"&&<SocialSignUpButton setIsEmailExist={setIsEmailExist}
                setshowSignInUrl={setshowSignInUrl}  nextStep={nextStep}  setLoading={setLoading}/>}
          </div>
        )}
        {userData.isEmailVerified && <EmailOtpVerify nextStep={nextStep} />}
      </div>
      </Spin>
    </div>
  );
};

export default SignUp;
