import { useSelector } from "react-redux";
import instanceAll from "./commonAxios";
import axios from "axios";

export const existEmailCheckingService = async (data) => {
  try {
    const response = await instanceAll.post("/existEmail", data);
    console.log("response existEmail:", response);
    if (response?.status === 200) {
      return {
        status: "success",
        data: response?.data,
      };
    } else {
      return {
        status: "failed",
        data: response?.data,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const existEmailCheckingServiceInVwp = async (data) => {
  try {
    // const response = await axios.get(
      // `http://172.16.10.117:1337/isEmailExists?email=${data.leadEmail}`
    // );
    const response = await axios.get(
      `https://cloud.${data.endPoint}/isEmailExists?email=${data.leadEmail}`
    );
    if (response?.status === 200) {
      return {
        status: "success",
        data: response?.data,
      };
    } else {
      return {
        status: "failed",
        data: response?.data,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const existMobileNumberCheckingService = async (data) => {
  try {
    const response = await instanceAll.post("/isMobileNumberExist", data);
    console.log("response existEmail:", response);
    if (response?.status === 200) {
      return {
        status: "success",
        data: response?.data,
      };
    } else {
      return {
        status: "failed",
        data: response?.data,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const existMobileNumberCheckingServiceInVwp = async (data) => {
  try {
    // const response = await axios.post(
    //   "http://172.16.10.117:1337/isMobileNumberExists", //https://cloud.voltusone.com
    //   data
    // );
    const response = await axios.post(
      `https://cloud.${data.endPoint}/isMobileNumberExists`, //https://cloud.voltusone.com
      data
    );

    console.log("response existEmail:", response);
    if (response?.status === 200) {
      return {
        status: "success",
        data: response?.data,
      };
    } else {
      return {
        status: "failed",
        data: response?.data,
      };
    }
  } catch (error) {
    console.log(error);
  }
};
