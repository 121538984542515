import React, { memo, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";
import "../styles/App.scss";
import vwLogo from "../assets/images/vw-logo.png";
import { Modal, Steps } from "antd";
import CreatePassword from "./completeDetails/CreatePassword";
import TeamSize from "./completeDetails/TeamSize";
import Details from "./completeDetails/Details";
import MobileSignUp from "./mobileVerify/MobileSignUp";
import EmailSignUp from "./emailVerfy/EmailSignup";
import { useLocation } from "react-router-dom";
import { fetchImageUrlService } from "../services/fetchImageUrlService";
import { Skeleton } from "antd";

const AccountUserTemplate = ({ stepNumber, imageUrl }) => {
  return (
    <>
      {/* <ToastContainer position="top-center" autoClose={2000} /> */}
      {/* <Modal
        open={true}
        closable={false}
        footer={null}
        width="730px"
        className="modal"
      > */}
      <div className="modal">
        <div className="vwContainer">
          <div className="vwHeader">
            <div className="vwLogo">
              {imageUrl && (
                <img
                  src={imageUrl}
                  width="200px"
                  alt="compaign logo"
                  srcSet=""
                />
              )}
              {!imageUrl && (
                <div style={{ width: "200px", height: "38px" }}>
                  <Skeleton.Input active={true} size="200px" block={true} />
                </div>
              )}
            </div>
          </div>
          <Steps
            className="signup-steps"
            size="small"
            current={stepNumber}
            responsive={false}
            items={[{}, {}, {}, {}, {}]}
          />
          {stepNumber == 0 && <EmailSignUp nextStep={1}/>}
          {stepNumber == 1 && <MobileSignUp nextStep={2}/>}
          {stepNumber == 2 && <Details nextStep={3}/>}
          {stepNumber == 3 && <TeamSize nextStep={4}/>}
          {stepNumber == 4 && <CreatePassword />}
        </div>
        {/* </Modal> */}
      </div>
    </>
  );
};

export default memo(AccountUserTemplate);
