import axios from "axios";
import { buildConstants } from "../utilies/buildConstants";

export default axios.create({
  // baseURL: "https://7xzss2qsx3.execute-api.us-east-1.amazonaws.com/Prod/",
  baseURL: buildConstants.backendServer,

  headers: {
    "Content-Type": "application/json",
  },
});
