import React, { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import Cookies from "js-cookie";
import {
  getGeoApiLocation,
  loader,
  onkeyPressHandler,
} from "../../utilies/Utilies";

import { useDispatch, useSelector } from "react-redux";
import {
  email,
  firstName,
  lastName,
  leadId,
  stepNavigate,
  isSocialLogin,
} from "../../features/userData/userDataSlice";
import { EmailSignupService } from "../../services/signupApiService";
import {
  existEmailCheckingService,
  existEmailCheckingServiceInVwp,
} from "../../services/existEmailAndMobileService";

import { buildConstants } from "../../utilies/buildConstants";

function GoogleSignup(props) {
  const { setIsEmailExist, setshowSignInUrl } = props;

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  const [countryCode, setCountryCode] = useState();
  const [type, setType] = useState("email");

  useEffect(() => {
    getGeoApiLocation(setCountryCode, type,userData.ipnfoToken);
  }, [userData]);

  const submitHandler = async (userFirstName, userLastName, userEmail) => {
    try {
      if (true) {
        //!isEmailExist
        // setLoading(true);
        let data = {
          firstName: userFirstName,
          lastName: userLastName,
          emailId: userEmail,
          campaignId: 68576,
          sourceId: 1,
          journeyIndex: 1,
          countryCode: countryCode,
          isSocialLogin: true,
        };
        // setIsEmailVerified(true);
        const response = await EmailSignupService(data);
        console.log("email signup response", response);
        if (response?.status === "success") {
          // setLoading(false);
          dispatch(firstName(response?.data?.data?.firstName));
          dispatch(lastName(response?.data?.data?.lastName));
          dispatch(leadId(response?.data?.data?.leadId));
          dispatch(email(response?.data?.data?.leadEmail));
          // dispatch(stepNavigate(2))
          // navigate("/emailOtpVerify");
        } else {
          // setLoading(false);
          // toast.error("something went wrong");
        }
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const emailChecking = async (userEmail , userName) => {
    try {
      const response = await existEmailCheckingServiceInVwp({
        leadEmail: userEmail,
        endPoint: userData.endPoint,
      });
      console.log("vwpemailexist", response);
      if (response.data.available === true) {
        existEmailCheckingService({ leadEmail: userEmail })
          .then((response) => {
            console.log("email exist response",response);
            if ( response?.status === "success" && response?.data?.isEmailExisted === true) {
              setIsEmailExist(true);
              setshowSignInUrl(true);
              return false;
            } else {
              setIsEmailExist(false);
              // storing user data:
              submitHandler(userName[0], userName[1], userEmail);
              dispatch(email(userEmail));
              dispatch(firstName(userName[0]));
              dispatch(lastName(userName[1]));
              dispatch(isSocialLogin(true));
              dispatch(stepNavigate(1));

            }
          })
          .catch((error) => {
            console.log("catch");
            console.log(error);
            setIsEmailExist(false);
          });
      } else {
        setIsEmailExist(true);
        setshowSignInUrl(true);
        return false;
      }
    } catch (error) {
      setIsEmailExist(false);
      console.log("else");
    }
  };

  return (
    <GoogleOAuthProvider clientId={buildConstants.googleClientId}>
      <GoogleLogin
        onSuccess={async (credentialResponse) => { 
          // console.log({credentialResponse});
          if (credentialResponse.credential != null) {
            const USER_CREDENTIAL = jwtDecode(credentialResponse.credential);
            // console.log(USER_CREDENTIAL);
            const name = USER_CREDENTIAL.name.split(" ");
            const emailCheck = await emailChecking(USER_CREDENTIAL.email ,name);
            // getSignInResponse(USER_CREDENTIAL.email);
          }
          // console.log(credentialResponse);
          // console.log('first')
        }}
        onError={() => {
          console.log("Login Failed");
        }}
        text={"continue_with"}
        size={"large"}
        width="300px"
      />
    </GoogleOAuthProvider>
  );
}
export default GoogleSignup;
